import { FC } from 'react';
import { Application } from '@raydiant/api-client-js';
import REApplicationCard from 'raydiant-elements/application/ApplicationCard';
import { localizeAppStrings } from '../../utilities';

interface MarketplaceCardProps {
  application: Application;
  onClick?: () => void;
  auto?: boolean;
}

const MarketplaceCard: FC<MarketplaceCardProps> = ({
  application,
  onClick,
  auto,
}) => {
  return (
    <REApplicationCard
      application={{
        ...application,
        currentAppVersion: {
          ...application.currentAppVersion,
          name: application?.name || application.currentAppVersion.name,
          strings: {
            ...localizeAppStrings(application.currentAppVersion.strings),
            callToAction:
              application?.currentAppVersion?.name || application?.name,
          },
        },
      }}
      onClick={onClick}
      auto={auto}
    />
  );
};

export default MarketplaceCard;
