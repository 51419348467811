import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectUserIsFetching = (state: RootState) => state.user.isFetching;

export const selectUserRoles = (state: RootState) => state.user.roles;

export const selectUserPreferences = (state: RootState) =>
  state.user.preferences;

export const selectProofOfPublishEnabled = createSelector(
  [selectUserRoles],
  // TODO: Remove selectProofOfPublishEnabled from codebase after successfully deploying to production.
  // I left this commented as an easy way to role back if we need to.
  // roles => roles.includes('proof_of_publish'),
  () => true,
);

export const selectIsRaydiantSuperAdmin = createSelector(
  [selectUserRoles],
  (roles) =>
    roles.includes('admin_dashboard_user') ||
    roles.includes('raydiant_superadmin'),
);

export const selectIsCustomerSupport = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('customer_support'),
);

export const selectIsEnterpriseUser = createSelector(
  [selectUserProfile],
  (profile) => !!profile?.domainId,
);

export const selectHomeTabPreference = createSelector(
  [selectUserPreferences],
  (preferences) => {
    return preferences ? preferences.homeTab || 'screens' : null;
  },
);

export const selectIsNestedPlaylistsEnabled = createSelector(
  [selectUserRoles],
  () => true,
  // TODO: Add Auth0 role.
  // roles => roles.includes('nested_playlists'),
);

export const selectIsDeveloper = createSelector([selectUserRoles], (roles) =>
  roles.includes('developer'),
);

export const selectIsAppReviewer = createSelector([selectUserRoles], (roles) =>
  roles.includes('app_reviewer'),
);

export const selectIsSiteCorpDashbordEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('sitecore_dashboard'),
);

export const selectIsUserAIEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('ai_device_options'),
);

export const selectUserHasRole = createSelector(
  [selectUserRoles],
  (roles) => (role: string) => roles.includes(role),
);

export const selectIsMarketplaceEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('marketplace'),
);

export const selectIsServiceCloudChatEnabled = createSelector(
  [selectUserRoles],
  (roles) => roles.includes('service_cloud'),
);
