import * as D from '../clients/mira/types/Device';

export default function getDeviceType(device: D.Device): string {
  if (device.isAudioOnly) return 'Audio Mode';
  if (new Set(['SecondScreen', 'Poly', 'Brightsign', 'BrightSign']).has(device.model)) {
    if (device.model === 'Brightsign') return 'BrightSign';
    return device.model;
  };
  if (device.isLite) return 'Lite';
  return '';
}
