import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { batchActions } from 'redux-batched-actions';
import { getType } from 'typesafe-actions';
import {
  Device,
  ResourceACL,
  RecentDeviceError,
} from '@raydiant/api-client-js';
import * as deviceActions from '../actions/devices';
import miraClient from '../clients/miraClient';
import { isDevicePublishing, isDeviceOnline } from '../utilities';
import logger from '../logger';
import config from '../config';
import {
  selectDevicesById,
  selectPlaylistStatusByDeviceId,
  DevicesById,
  PlaylistStatusById,
} from '../selectors/v2/devices';
import { selectProofOfPublishEnabled } from '../selectors/user';

type FetchDeviceAction = ReturnType<typeof deviceActions.fetchDevice>;
type UpdateDeviceAction = ReturnType<typeof deviceActions.updateDevice>;
type RegisterDeviceAction = ReturnType<typeof deviceActions.registerDevice>;
type RestartDeviceAction = ReturnType<typeof deviceActions.restartDevice>;
type PublishDeviceAction = ReturnType<typeof deviceActions.publishDevice>;
type PublishAllAction = ReturnType<typeof deviceActions.publishAll>;
type ShareDeviceAction = ReturnType<typeof deviceActions.shareDevice>;
type RemoveDeviceResourceACL = ReturnType<
  typeof deviceActions.removeDeviceResourceACL
>;
type PollDevicesWithPendingPublishAction = ReturnType<
  typeof deviceActions.pollDevicesWithPendingPublish
>;

export const fetchDevices = function* () {
  try {
    yield put(deviceActions.fetchDevicesAsync.request());
    const devices: Device[] = yield call(() => miraClient.getDevices());
    yield put(deviceActions.fetchDevicesAsync.success(devices));
    return devices;
  } catch (error: any) {
    logger.error(error);
    yield put(deviceActions.fetchDevicesAsync.failure(error));
  }
};

export const fetchDevice = function* (id: string) {
  try {
    yield put(deviceActions.fetchDeviceAsync.request(id));
    const data: Device = yield call(() => miraClient.getDevice(id));
    yield put(deviceActions.fetchDeviceAsync.success(data));
    return data;
  } catch (error: any) {
    logger.error(error);
    yield put(deviceActions.fetchDeviceAsync.failure({ error, id }));
  }
};

const pollingDevices: { [deviceId: string]: boolean } = {};
const pollDevices = function* () {
  const deviceIds = Object.keys(pollingDevices);

  const devices: Device[] = yield call(() =>
    miraClient.getDevices({ ids: deviceIds }),
  );

  yield put(deviceActions.fetchDevicesAsync.success(devices));

  for (const device of devices) {
    if (!isDevicePublishing(device)) {
      delete pollingDevices[device.id];
    }
  }

  if (Object.keys(pollingDevices).length > 0) {
    yield delay(config.devicePublishPollMS);
    yield call(pollDevices);
  }
};

export const pollDevicesWithPendingPublish = function* (
  deviceIds: string[],
  lastLoadedDate: string,
) {
  // Don't poll for devices if user does not have proof of publish feature enabled.
  // TODO: Remove when we release proof of publish to all.
  const isProofOfPublishEnabled: string[] = yield select(
    selectProofOfPublishEnabled,
  );
  if (!isProofOfPublishEnabled) return;

  const isNotPolling = Object.keys(pollingDevices).length === 0;
  const devicesById: DevicesById = yield select(selectDevicesById);
  const paylistStatusByDeviceId: PlaylistStatusById = yield select(
    selectPlaylistStatusByDeviceId,
  );

  for (const id of deviceIds) {
    const device = devicesById[id];
    const playlistStatus = paylistStatusByDeviceId[id];
    if (!device) continue;

    const isDevicePublishable = !!playlistStatus;
    // Poll the device if it's still publishing and online and is not in a
    // publishable state
    if (
      isDevicePublishing(device) &&
      isDeviceOnline(device, lastLoadedDate) &&
      !isDevicePublishable
    ) {
      pollingDevices[id] = true;
    }
  }

  const hasDevicesToPoll = Object.keys(pollingDevices).length > 0;

  if (isNotPolling && hasDevicesToPoll) {
    yield call(pollDevices);
  }
};

const publishDevice = function* (id: string) {
  try {
    yield put(deviceActions.publishDeviceAsync.request(id));
    const device: Device = yield call(() => miraClient.publishDevice(id));
    yield put(deviceActions.publishDeviceAsync.success(device));
  } catch (error: any) {
    logger.error(error);
    yield put(deviceActions.publishDeviceAsync.failure({ error, id }));
  }
};

const watchPublishDevice = function* () {
  while (true) {
    const action: PublishDeviceAction = yield take(
      getType(deviceActions.publishDevice),
    );
    try {
      yield call(publishDevice, action.payload);
      yield call(
        pollDevicesWithPendingPublish,
        [action.payload],
        new Date().toISOString(),
      );
    } catch (e: any) {
      logger.error(e.message);
    }
  }
};

const publishAllDevices = function* (deviceIds: string[]) {
  // Show all devices as "publishing..." immediately
  yield put(
    batchActions(
      deviceIds.map((id) => deviceActions.publishDeviceAsync.request(id)),
    ),
  );

  const queue = [...deviceIds];
  const failuresByDeviceId: { [key: string]: number } = {};
  const batchSize = 10;
  const maxRetries = 2;

  while (queue.length !== 0) {
    const batch = queue.splice(0, batchSize);
    const successes: Device[] = [];
    const failures: Array<{ deviceId: string; error: Error }> = [];

    yield all(
      batch.map((deviceId) =>
        call(async () => {
          const retries = failuresByDeviceId[deviceId] ?? 0;
          try {
            // Add a delay if we are retrying a failed batch item.
            await new Promise((resolve) => setTimeout(resolve, retries * 1000));
            successes.push(await miraClient.publishDevice(deviceId));
          } catch (error: any) {
            if (retries < maxRetries) {
              failuresByDeviceId[deviceId] = retries + 1;
              queue.push(deviceId);
            } else {
              failures.push({ deviceId, error });
            }
          }
        }),
      ),
    );

    yield put(
      batchActions([
        ...successes.map((device) =>
          deviceActions.publishDeviceAsync.success(device),
        ),
        ...failures.map(({ deviceId, error }) =>
          deviceActions.publishDeviceAsync.failure({ id: deviceId, error }),
        ),
      ]),
    );

    yield call(
      pollDevicesWithPendingPublish,
      successes.map((device) => device.id),
      new Date().toISOString(),
    );
  }
};

const watchPublishAll = function* () {
  while (true) {
    const action: PublishAllAction = yield take(
      getType(deviceActions.publishAll),
    );

    yield call(publishAllDevices, action.payload);
  }
};

const watchRegisterDevice = function* () {
  while (true) {
    const action: RegisterDeviceAction = yield take(
      getType(deviceActions.registerDevice),
    );

    const { activationCode, name, isAudioOnly, timezone } = action.payload;
    const { onRegister, onError } = action.meta;

    try {
      yield put(
        deviceActions.registerDeviceAsync.request({
          activationCode,
          name,
          timezone,
        }),
      );
      let newDevice: Device = yield call(() =>
        miraClient.registerDevice({ activationCode, name, timezone }),
      );

      if (isAudioOnly) {
        const updatedDevice: Device = yield call(() =>
          miraClient.updateDevice(newDevice.id, {
            ...newDevice,
            isAudioOnly: true,
          }),
        );
        yield put(deviceActions.updateDeviceAsync.success(updatedDevice));
        newDevice = updatedDevice;
      }

      yield put(deviceActions.registerDeviceAsync.success(newDevice));

      if (onRegister) {
        onRegister(newDevice);
      }
    } catch (error: any) {
      logger.error(error);

      yield put(
        deviceActions.registerDeviceAsync.failure({
          ...action.payload,
          error: new Error(
            "Sorry, we couldn't activate your ScreenRay. Please double check your Activation Code and try again.",
          ),
        }),
      );

      if (onError) {
        onError(error);
      }
    }
  }
};

const watchFetchDevices = function* () {
  while (true) {
    yield take(getType(deviceActions.fetchDevices));
    yield call(fetchDevices);
  }
};

const watchFetchDevice = function* () {
  while (true) {
    const action: FetchDeviceAction = yield take(
      getType(deviceActions.fetchDevice),
    );
    yield call(fetchDevice, action.payload);
  }
};

const watchUpdateDevice = function* () {
  while (true) {
    const action: UpdateDeviceAction = yield take(
      getType(deviceActions.updateDevice),
    );
    try {
      yield put(deviceActions.updateDeviceAsync.request(action.payload));
      const device: Device = yield call(() =>
        miraClient.updateDevice(action.payload.id, action.payload),
      );
      yield put(deviceActions.updateDeviceAsync.success(device));

      if (action.meta.onUpdate) {
        action.meta.onUpdate(device);
      }
    } catch (error: any) {
      logger.error(error);
      yield put(
        deviceActions.updateDeviceAsync.failure({
          id: action.payload.id,
          error,
        }),
      );
    }
  }
};

const watchRestartDevice = function* () {
  while (true) {
    const action: RestartDeviceAction = yield take(
      getType(deviceActions.restartDevice),
    );
    try {
      yield put(deviceActions.restartDeviceAsync.request(action.payload));
      yield call(() => miraClient.restartDevice(action.payload));
      yield put(deviceActions.restartDeviceAsync.success(action.payload));
    } catch (error: any) {
      logger.error(error);
      yield put(
        deviceActions.restartDeviceAsync.failure({
          id: action.payload,
          error,
        }),
      );
    }
  }
};

const watchShareDevice = function* () {
  while (true) {
    const action: ShareDeviceAction = yield take(
      getType(deviceActions.shareDevice),
    );
    const { deviceId, profileId } = action.payload;
    const { onShare, onError } = action.meta;

    try {
      const devicesById: DevicesById = yield select(selectDevicesById);
      const device = devicesById[deviceId];
      if (!device) return;

      const resourceACL: ResourceACL = yield call(() =>
        miraClient.createResourceACL({
          resourceId: device.resource.id,
          grantProfileId: profileId,
          grants: ['read', 'update'],
        }),
      );

      yield put(deviceActions.addDeviceResourceACL({ deviceId, resourceACL }));

      if (onShare) {
        onShare(resourceACL);
      }
    } catch (error: any) {
      if (onError) {
        onError(error);
      }
      logger.error(error);
    }
  }
};

const watchRemoveDeviceResourceACL = function* () {
  while (true) {
    const action: RemoveDeviceResourceACL = yield take(
      getType(deviceActions.removeDeviceResourceACL),
    );
    const { aclId } = action.payload;

    try {
      // The store is optimistically updated via RemoveDeviceResourceACL so we
      // don't need to fire any additional actions.
      yield call(() => miraClient.deleteResourceACL(aclId));
    } catch (error: any) {
      logger.error(error);
    }
  }
};

const watchPollDevicesWithPendingPublish = function* () {
  while (true) {
    const action: PollDevicesWithPendingPublishAction = yield take(
      getType(deviceActions.pollDevicesWithPendingPublish),
    );
    yield fork(
      pollDevicesWithPendingPublish,
      action.payload.deviceIds,
      action.payload.lastLoadedDate,
    );
  }
};

const fetchRecentErrors = function* () {
  try {
    yield put(deviceActions.fetchRecentDeviceErrorsAsync.request());
    const recentErrors: RecentDeviceError[] = yield call(() =>
      miraClient.getRecentDeviceErrors(),
    );
    yield put(deviceActions.fetchRecentDeviceErrorsAsync.success(recentErrors));
    return recentErrors;
  } catch (error: any) {
    logger.error(error);
    yield put(deviceActions.fetchRecentDeviceErrorsAsync.failure(error));
  }
};

const watchFetchRecentErrors = function* () {
  while (true) {
    yield take(getType(deviceActions.fetchRecentDeviceErrors));
    yield fork(fetchRecentErrors);
  }
};

export default all([
  fork(watchPublishDevice),
  fork(watchPublishAll),
  fork(watchRegisterDevice),
  fork(watchFetchDevices),
  fork(watchFetchDevice),
  fork(watchRestartDevice),
  fork(watchUpdateDevice),
  fork(watchShareDevice),
  fork(watchRemoveDeviceResourceACL),
  fork(watchPollDevicesWithPendingPublish),
  fork(watchFetchRecentErrors),
]);
