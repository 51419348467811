import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Grid from '../Grid';
import GridItem from '../Grid/GridItem';
import Heading from 'raydiant-elements/core/Heading';

import history from '../../history';
import * as paths from '../../routes/paths';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';

const MarketplaceBanner = () => {
  const roles = useCurrentUserRoles();
  const classes = useStyles();

  if (!roles.isMarketplaceEnabled) return null;
  return (
    <Grid className={classes.wrapper}>
      <GridItem>
        <Heading size={2} weight={600} className={classes.heading}>
          Can't find the app you're looking for?
        </Heading>
        <div className={classes.text}>
          <Text large>Search hundreds of apps in the Marketplace.</Text>
          <Text large>New apps available weekly!</Text>
        </div>
        <Button
          label="Let's go!"
          onClick={() => history.push(paths.marketplace())}
          className={classes.button}
        />
      </GridItem>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      '&>:nth-child(2)': {
        backgroundColor: '#1b8ebe',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),

        [theme.breakpoints.down('xs')]: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
      },
    },
    button: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    heading: {
      color: '#ffffff',
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      color: '#ffffff',

      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  });
});

export default MarketplaceBanner;
