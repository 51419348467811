import ActionBar from 'raydiant-elements/core/ActionBar';
import Button from 'raydiant-elements/core/Button';
import Paper from 'raydiant-elements/core/Paper';
import FileDropper from 'raydiant-elements/core/FileDropper';
import PlaylistCard from 'raydiant-elements/playlist/PlaylistCard';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Spacer from 'raydiant-elements/layout/Spacer';
import withStyles, { WithStyles } from '../../components/withStyles';
import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import ApplicationCard from '../../components/ApplicationCard';
import * as presActions from '../../actions/presentations';
import * as A from '../../clients/mira/types/Application';
import * as P from '../../clients/mira/types/Presentation';
import * as U from '../../clients/mira/types/User';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import GridItem from '../../components/Grid/GridItem';
import MarketplaceBanner from '../../components/MarketplaceBanner';
import config from '../../config';
import history from '../../history';
import * as paths from '../../routes/paths';
import {
  createPresentationFromAppVersion,
  createDefaultPlaylist,
  isResourceDeleted,
  getDefaultThemeForUser,
} from '../../utilities';
import { createNewId } from '../../utilities/identifiers';
import { ThemesById } from '../../selectors/v2/themes';
import * as presentationPageActions from '../PresentationPage/actions';
import * as addContentPageActions from '../AddContentPage/actions';
import * as applicationsPageActions from './actions';
import getMimeTypesForAppVersion from './getMimeTypesForAppVersion';
import mapStateToProps from './selectors';

import styles from './ApplicationsPage.styles';

export interface ApplicationsPageProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  applications: A.Application[];
  premiumApplications: A.Application[];
  backTo: string;
  selectionId?: string;
  folderId?: string;
  isModal?: boolean;
  currentUser?: U.Profile;
  themesById: ThemesById;
  createPlaylistTo: string;
  loadApplicationsPage: typeof applicationsPageActions.loadApplicationsPage;
  selectItem: typeof addContentPageActions.selectItem;
  savePresentation: typeof presActions.savePresentation;
  clearUnsavedPresentation: typeof presentationPageActions.clearUnsavedPresentation;
}

const defaultPlaylist = createDefaultPlaylist({ name: 'Create a Playlist' });

// Trigger the file upload dialog when creating pictures or videos.
const quickUploadAppIds = [config.picturesAppId, config.videosAppId];

// Allow pictures, videos and presenter to handle drag and drop file uploads.
const fileUploadAppIds = [
  config.picturesAppId,
  config.videosAppId,
  config.presenterAppId,
];

class ApplicationsPage extends Component<ApplicationsPageProps> {
  fileInput = createRef<HTMLInputElement>();

  componentDidMount() {
    this.props.loadApplicationsPage();
  }

  getBackToLabel() {
    return 'Back to Library';
  }

  goBack = () => {
    const { backTo } = this.props;
    history.push(backTo);
  };

  newPresentation = (applicationId: string) => {
    const { backTo, selectionId, folderId, clearUnsavedPresentation } =
      this.props;

    clearUnsavedPresentation(applicationId);

    history.push(
      paths.newPresentation({
        applicationId,
        selectionId,
        folderId,
        backToLabel: this.getBackToLabel(),
        // We don't want to come back to the app selector page,
        // forward the backTo to the next page.
        backTo,
        // On presentation save, we want to navigate to the page
        // the applications page was launched from.
        //  ie. /library, /screens, /screens/:id/default, etc...
        saveTo: backTo,
      }),
    );
  };

  handleFileInputChange = () => {
    if (this.fileInput.current && this.fileInput.current.files) {
      this.createPresentationsForFiles(this.fileInput.current.files);
    }
  };

  openFileBrowser = (mimetypes: string[]) => {
    if (this.fileInput.current) {
      this.fileInput.current.accept = mimetypes.join(',');
      this.fileInput.current.click();
    }
  };

  createPresentationsForFiles = async (fileList: FileList) => {
    const {
      applications,
      savePresentation,
      backTo,
      selectItem,
      selectionId,
      folderId,
      currentUser,
      themesById,
    } = this.props;

    if (!currentUser) return;

    const files = Array.from(fileList);
    const fileUploadApps = fileUploadAppIds.map((appId) =>
      applications.find((app) => app.id === appId),
    );

    const presentationsToSave: Array<Promise<P.Presentation>> = [];

    files.forEach((file) => {
      const fileUploadApp = fileUploadApps.find((app) => {
        if (!app) return null;
        const mimeTypes = getMimeTypesForAppVersion(app.currentAppVersion);
        return mimeTypes.some((mimeType) => mimeType === file.type);
      });

      // Application not found for file type, ignore file.
      if (!fileUploadApp) return null;

      const fileUploadProp =
        fileUploadApp.currentAppVersion.presentationProperties.find(
          (p) => p.type === 'file',
        );

      // Ignore file if application doesn't have a file upload prop.
      // This shouldn't happen since getMimeTypesForAppVersion checks for
      // a file upload prop already.
      if (!fileUploadProp) return null;

      // Create a default presentation for the file.
      const presentation = createPresentationFromAppVersion(
        fileUploadApp.currentAppVersion,
        currentUser.id,
        getDefaultThemeForUser(currentUser, themesById),
      );
      presentation.name = file.name;
      presentation.applicationVariables[fileUploadProp.name] = {
        filename: file.name,
        'content-type': file.type,
        'content-length': file.size,
      };

      // Make sure we set the source to pdf for presenter apps.
      // https://github.com/mirainc/powerpoint/blob/staging/src/constant/sources.js#L3
      if (presentation.applicationId === config.presenterAppId) {
        presentation.applicationVariables.source = 'pdf';
      }

      // We currently don't support file uploads that aren't at the
      // root level (ie. file upload inside an array input) but we
      // need to fake the path anyways.
      const path = ['applicationVariables', fileUploadProp.name];
      presentationsToSave.push(
        new Promise((resolve) =>
          savePresentation(presentation, {
            fileUploads: [{ path, file, localUrl: URL.createObjectURL(file) }],
            folderId,
            onSave: (savedPresentation) => resolve(savedPresentation),
          }),
        ),
      );
    });

    const savedPresentations = await Promise.all(presentationsToSave);
    if (selectionId) {
      savedPresentations.forEach(({ id }) => {
        selectItem({
          selectionId,
          item: { id, type: 'presentation' },
        });
      });
    }

    history.push(backTo);
  };

  newPlaylist = () => {
    const { backTo, folderId, createPlaylistTo } = this.props;

    if (createPlaylistTo) {
      history.push(createPlaylistTo);
    } else {
      history.push(
        paths.newPlaylist({
          playlistId: createNewId(),
          folderId,
          // We don't want to come back to the app selector page,
          // forward the backTo to the next page.
          backTo,
          saveTo: backTo,
          backToLabel: this.getBackToLabel(),
        }),
      );
    }
  };

  renderHeaderBar() {
    return (
      this.props.isModal && (
        <ActionBar>
          Create a presentation, or just drag an image, video or PDF file into
          this window to create one instantly.
          <Spacer />
          <Button label="Back to Library" onClick={this.goBack} />
        </ActionBar>
      )
    );
  }

  renderFooterBar() {
    return (
      !this.props.isModal && (
        <ActionBar>
          <Button label="Cancel" onClick={this.goBack} />
          <Spacer />
        </ActionBar>
      )
    );
  }

  renderAvailableApps() {
    const { applications } = this.props;

    return (
      <Grid>
        <GridItem key="new-playlist">
          <PlaylistCard playlist={defaultPlaylist} onClick={this.newPlaylist} />
        </GridItem>
        {applications
          .filter((app) => !app.isDeprecated && !isResourceDeleted(app))
          .map((app) => (
            <GridItem key={app.id}>
              <ApplicationCard
                application={app}
                onClick={() => {
                  if (quickUploadAppIds.includes(app.id)) {
                    this.openFileBrowser(
                      getMimeTypesForAppVersion(app.currentAppVersion),
                    );
                  } else {
                    this.newPresentation(app.id);
                  }
                }}
              />
            </GridItem>
          ))}
      </Grid>
    );
  }

  render() {
    const { isLoading, backTo, isModal, classes, applications } = this.props;

    const pageProps = {
      title: 'Create a presentation',
      backTo,
      hideNavigation: isModal,
    };

    if (isLoading && !applications) {
      return (
        <Page {...pageProps}>
          <Paper color="light" className={classes.paper}>
            {this.renderHeaderBar()}
            <Spacer />
            {this.renderFooterBar()}
          </Paper>
        </Page>
      );
    }

    return (
      <Page {...pageProps}>
        <Paper color="light" className={classes.paper}>
          <FileDropper
            title="Drag your pictures, videos or PDFs here to quick upload."
            onDrop={this.createPresentationsForFiles}
          />
          <input
            ref={this.fileInput}
            multiple
            type="file"
            onChange={this.handleFileInputChange}
            style={{ position: 'absolute', top: -9999, opacity: 0 }}
          />
          {this.renderHeaderBar()}
          <Scrollable>
            {this.renderAvailableApps()}
            <MarketplaceBanner />
          </Scrollable>
          {this.renderFooterBar()}
        </Paper>
      </Page>
    );
  }
}

export default connect(mapStateToProps, {
  ...applicationsPageActions,
  ...addContentPageActions,
  ...presentationPageActions,
  ...presActions,
})(withStyles(styles)(ApplicationsPage));
