import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Switch from 'raydiant-elements/core/Switch';
import Link from 'raydiant-elements/core/Link';
import Column from 'raydiant-elements/layout/Column';
import { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DM from '../../clients/mira/types/Domain';
import * as domainActions from '../../actions/domains';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import EnableMultiTimezone from './EnableMultiTimezone/EnableMultiTimezone';
import {
  selectUserProfile,
} from '../../selectors/user';
import { canEnableDomainMultiTimezone } from '../../utilities';

interface DomainSettingsProps {}

const DomainSettings: FC<DomainSettingsProps> = () => {
  const dispatch = useDispatch();

  // Selectors

  const domain = useSelector(selectDomainForCurrentUser);
  const profile = useSelector(selectUserProfile);
  const isMultiTimezoneEnabled = domain?.isMultiTimezoneEnabled;

  // State

  const [updatedDomainName, setDomainName] = useState<string | null>(null);
  const [updatedDomainMfaRequired, setDomaimMfaRequired] = useState<
    boolean | null
  >(null);

  // Callbacks

  const updateDomain = useCallback(
    (updatedDomain: Partial<DM.Domain>) => {
      if (!domain) return;
      dispatch(
        domainActions.updateDomain({
          ...domain,
          ...updatedDomain,
        }),
      );
    },
    [dispatch, domain],
  );

  const updateDomainName = useCallback(() => {
    if (!domain) return;
    if (updatedDomainName === null) return;
    if (updatedDomainName === domain.name) return;
    updateDomain({ name: updatedDomainName });
  }, [updateDomain, updatedDomainName, domain]);

  // Side-effects

  // Update mfa required when changed.
  useEffect(() => {
    if (!domain) return;
    if (updatedDomainMfaRequired === null) return;
    if (updatedDomainMfaRequired === domain.mfaRequired) return;
    updateDomain({ mfaRequired: updatedDomainMfaRequired });
  }, [updateDomain, domain, updatedDomainMfaRequired]);

  return (
    <Column doubleMargin>
      <Heading size={5} overline>
        Domain Settings
      </Heading>

      <Heading>
        <Text
          editable
          value={updatedDomainName ?? domain?.name ?? ''}
          onChange={setDomainName}
          onBlur={updateDomainName}
        />
      </Heading>

      <Switch
        label="2-step verification"
        checked={updatedDomainMfaRequired ?? domain?.mfaRequired ?? false}
        onChange={setDomaimMfaRequired}
        helperText={
          <>
            Enforces 2-step verification as a requirement for all Domain
            accounts.{' '}
            <Link
              target="_blank"
              href="https://support.raydiant.com/hc/en-us/articles/360053600633#Enterprise"
            >
              Tell me more
            </Link>
          </>
        }
      />
      {canEnableDomainMultiTimezone(profile) && !isMultiTimezoneEnabled && (
        <>
          <EnableMultiTimezone domainUser={true} />
          <br />
          <br />
        </>
      )}
    </Column>
  );
};

export default DomainSettings;
