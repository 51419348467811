import { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Theme, Presentation } from '@raydiant/api-client-js';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import * as themeActions from '../../actions/themes';
import { selectThemesById } from '../../selectors/v2/themes';
import { hasThemeChanged } from '../../utilities';
import { selectLoadingStatus } from './selectors';
import { useCallback } from 'react';
import useQueryParams from './useQueryParams';
import * as actions from './actions';
import getUnsavedThemeId from './getUnsavedThemeId';
import getPageUrl from './getPageUrl';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import * as deviceActions from '../../actions/devices';

interface ThemeFormActionsProps {
  theme?: Theme;
  pendingBackgroundImageUpload?: themeActions.ThemeFile;
  presentation: Presentation | undefined;
  onChange: (theme: Theme) => void;
}

const ThemeFormActions = ({
  presentation,
  pendingBackgroundImageUpload,
  theme,
  onChange,
}: ThemeFormActionsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  // Selectors

  const loadingStatus = useSelector(selectLoadingStatus);
  const themesById = useSelector(selectThemesById);
  const [saveStatus, setSaveStatus] = useState<'' | 'pending' | 'success'>('');
  const [addToListStatus, setAddToListStatus] = useState<'' | 'success'>('');

  // Memoizers

  const originalTheme = theme && themesById[theme.id];

  const isFormDirty = useMemo(() => {
    if (!theme) return false;
    // The form is dirty if we are creating a new presentation (no original presentation).
    if (!originalTheme) return true;

    return hasThemeChanged(originalTheme, theme);
  }, [theme, originalTheme]);

  // Callbacks

  const goBack = useCallback(() => {
    if (theme) {
      dispatch(actions.clearUnsavedTheme(getUnsavedThemeId(theme)));
    }

    history.push(queryParams.backTo);
  }, [queryParams.backTo, history, theme, dispatch]);

  const saveTheme = useCallback(
    (onSave?: (savedTheme: Theme) => void) => {
      if (!theme) return;

      setSaveStatus('pending');

      const redirectToEditAfterSave = (savedTheme: Theme) => {
        setSaveStatus('success');

        history.replace(
          getPageUrl({
            presentation,
            theme: savedTheme,
            queryParams: { ...queryParams, didSave: true },
          }),
        );
      };

      if (theme.id) {
        dispatch(
          themeActions.updateTheme(theme, {
            onUpdate: (savedTheme) => {
              dispatch(actions.clearUnsavedTheme(getUnsavedThemeId(theme)));
              redirectToEditAfterSave(savedTheme);
              if (onSave) {
                onSave(savedTheme);
              }
            },
            pendingBackgroundImageUpload,
          }),
        );
      } else {
        dispatch(
          themeActions.createTheme(theme, {
            onCreate: (savedTheme) => {
              dispatch(actions.clearUnsavedTheme(getUnsavedThemeId(theme)));
              redirectToEditAfterSave(savedTheme);

              // Set theme id of unsaved presentation to savedTheme.id
              if (presentation) {
                dispatch(
                  actions.setUnsavedPresentation({
                    ...presentation,
                    themeId: savedTheme.id,
                  }),
                );
              }

              if (onSave) {
                onSave(savedTheme);
              }
            },
            pendingBackgroundImageUpload
          }),
        );
      }
    },
    [theme, presentation, dispatch, history, queryParams, pendingBackgroundImageUpload],
  );

  const doneEditingTheme = useCallback(() => {
    if (!theme) return;

    const redirect = () => {
      history.push(queryParams.saveTo);
    };

    if (isFormDirty) {
      saveTheme(redirect);
      dispatch(deviceActions.fetchDevices());
    } else {
      redirect();
    }
  }, [theme, queryParams, saveTheme, isFormDirty, history, dispatch]);

  const addToList = useCallback(() => {
    if (!presentation) return;
    if (!theme) return;

    onChange({
      ...theme,
      presentationId: null,
    });

    setAddToListStatus('success');
  }, [theme, presentation, onChange]);

  // Effects

  // Reset Add To List after 3 seconds
  useEffect(() => {
    if (addToListStatus !== 'success') return;

    const timeout = setTimeout(() => {
      setAddToListStatus('');
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [addToListStatus]);

  // Render

  const shouldDisableSave =
    loadingStatus !== 'success' || !isFormDirty || saveStatus === 'pending';
  const shouldDisableDone = shouldDisableSave && !queryParams.didSave;
  const shouldDisableAddToList = !theme?.presentationId;

  return (
    <ThemeSelector color="light">
      <ActionBar variant="footer">
        <ActionBar.Action
          color="error"
          icon={<CancelIcon />}
          label="Cancel"
          onClick={goBack}
        />

        <ActionBar.Action
          color="success"
          icon={<CheckCircleIcon />}
          label={saveStatus === 'pending' ? 'Saving...' : 'Done'}
          disabled={shouldDisableDone}
          onClick={doneEditingTheme}
        />

        <ActionBar.Action
          icon={<ListAltIcon />}
          label={addToListStatus === 'success' ? 'Added!' : 'Add To List'}
          disabled={shouldDisableAddToList}
          onClick={addToList}
        />
      </ActionBar>
    </ThemeSelector>
  );
};

export default ThemeFormActions;
