import Paper from 'raydiant-elements/core/Paper';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Heading1 from 'raydiant-elements/typography/Heading1';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import {
  selectUserProfile,
  selectIsRaydiantSuperAdmin,
  selectIsCustomerSupport,
  selectIsEnterpriseUser,
  selectProofOfPublishEnabled,
} from '../../selectors/user';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import {
  canEditDomain,
  canUpdatePaymentMethod,
  getMasqueradeUser,
  getRemoteAssistUser,
} from '../../utilities';
import * as paths from '../../routes/paths';
import Page from '../../components/Page';
import { loadAccountPage } from './actions';
import useStyles from './AccountPage.styles';
import AccountDetails from './AccountDetails';
import Masquerade from './Masquerade';
import RemoteAssistance from './RemoteAssistance';
import AccountSettings from './AccountSettings';
import DomainSettings from './DomainSettings';
import MemberAccounts from './MemberAccounts';
import MemberAccountEdit from './MemberAccountEdit';
import PlaybackReports from './PlaybackReports';
import UpdatePaymentMethod from './UpdatePaymentMethod/UpdatePaymentMethod';
import EnableMultiTimezone from './EnableMultiTimezone/EnableMultiTimezone';

interface AccountPageProps {}

const AccountPage: FC<AccountPageProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch<{ profileId?: string }>({
    path: paths.accountDomainMembers.pattern,
  });
  const selectedProfileId = match?.params?.profileId ?? '';

  // Selectors

  const profile = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);
  const isRaydiantSuperAdmin = useSelector(selectIsRaydiantSuperAdmin);
  const isCustomerSupport = useSelector(selectIsCustomerSupport);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const isProofOfPublishEnabled = useSelector(selectProofOfPublishEnabled);
  const isMultiTimezoneEnabled = profile?.isMultiTimezoneEnabled;

  // Side-effects

  useEffect(() => {
    dispatch(loadAccountPage());
  }, [dispatch]);

  // Callbacks

  const setSelectedProfileId = useCallback(
    (profileId: string | null) => {
      if (profileId) {
        history.push(paths.accountDomainMembers(profileId));
      } else {
        history.push(paths.account());
      }
    },
    [history],
  );

  // Render

  if (!profile || (profile?.domainId && !domain)) {
    return (
      <Page title="Account">
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  const isDomainEditable = canEditDomain(profile) && isEnterpriseUser;
  const isMasqueradeEnabled = isRaydiantSuperAdmin || !!getMasqueradeUser();
  const isRemoteAssistanceEnabled =
    isCustomerSupport || !!getRemoteAssistUser();
  const isPlaybackReportsEnabled = isEnterpriseUser && isProofOfPublishEnabled;
  const selectedProfile = (domain?.r.profiles || []).find(
    (p) => p.id === selectedProfileId,
  );

  return (
    <Page title="Account">
      <Scrollable>
        <div className={classes.container}>
          <div
            className={cn(
              classes.account,
              !isDomainEditable && classes.accountNoDomain,
            )}
          >
            <Paper className={classes.section} color="light">
              <div className={classes.sectionHeader}>
                <Heading1>Account</Heading1>
              </div>
              <div className={classes.sectionBody}>
                <AccountDetails />
                <br />
                <br />
                {canUpdatePaymentMethod(profile, isEnterpriseUser) && (
                    <>
                      <UpdatePaymentMethod />
                      <br />
                      <br />
                    </>
                  )}
                {!isMultiTimezoneEnabled && !isEnterpriseUser && (
                  <>
                    <EnableMultiTimezone />
                    <br />
                    <br />
                  </>
                )}
                <AccountSettings />
              </div>
              {isPlaybackReportsEnabled && (
                <div className={classes.sectionBody}>
                  <PlaybackReports />
                </div>
              )}
              {isMasqueradeEnabled && (
                <div className={classes.sectionBody}>
                  <Masquerade />
                </div>
              )}
              {isRemoteAssistanceEnabled && (
                <div className={classes.sectionBody}>
                  <RemoteAssistance />
                </div>
              )}
            </Paper>
          </div>

          {isDomainEditable && (
            <div className={classes.domain}>
              <Paper className={classes.section} color="light">
                <div className={classes.sectionHeader}>
                  <Heading1>Enterprise Domain</Heading1>
                </div>
                <div className={classes.domainInner}>
                  <div className={classes.domainInfo}>
                    <div className={classes.sectionBody}>
                      <DomainSettings />
                    </div>
                    <div className={classes.sectionBody}>
                      <MemberAccounts
                        selectedProfileId={selectedProfileId}
                        onEditProfile={setSelectedProfileId}
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          )}

          <PaperModal
            color="lightGrey"
            className={classes.profile}
            open={!!selectedProfile}
            onClose={() => setSelectedProfileId(null)}
          >
            {selectedProfile && <MemberAccountEdit profile={selectedProfile} />}
          </PaperModal>
        </div>
      </Scrollable>
    </Page>
  );
};

export default AccountPage;
