import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountPage from '../pages/AccountPage';
import ApplicationPage from '../pages/ApplicationPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import DevicesPage from '../pages/DevicesPage';
import LegacyChangePasswordBackstopPage from '../pages/LegacyChangePasswordBackstopPage';
import LibraryPage from '../pages/LibraryPage';
import LoginCallbackPage from '../pages/LoginCallbackPage';
import LoginPage from '../pages/LoginPage';
import PlaylistPage from '../pages/PlaylistPage';
import PresentationPage from '../pages/PresentationPage';
import AddContentPage from '../pages/AddContentPage';
import UnsubscribePage from '../pages/UnsubscribePage';
import HomePage from '../pages/HomePage';
import EnterpriseWelcomePage from '../pages/EnterpriseWelcomePage';
import ActivatePage from '../pages/ActivatePage';
import * as paths from './paths';
import AnalyticsPage from '../pages/AnalyticsPage';
import MarketplacePage from '../pages/MarketplacePage';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import { useSelector } from 'react-redux';
import { selectIsEnterpriseUser } from '../selectors/user';

const Routes: React.SFC = () => {
  const roles = useCurrentUserRoles();
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  return (
    <Switch>
      <Route path={paths.loginCallback.pattern} component={LoginCallbackPage} />
      <Route path={paths.login.pattern} component={LoginPage} />
      <Route path={paths.activate.pattern} component={ActivatePage} />
      <Route path={paths.screens.pattern} component={DevicesPage} />
      <Route path={paths.library.pattern} component={LibraryPage} />
      <Route
        exact
        path={[
          paths.newPresentation.pattern,
          paths.newPresentationNewTheme.pattern,
          paths.newPresentationEditTheme.pattern,
          paths.newPresentationManageThemes.pattern,
          paths.editPresentation.pattern,
          paths.editPresentationNewTheme.pattern,
          paths.editPresentationEditTheme.pattern,
          paths.editPresentationManageThemes.pattern,
        ]}
        component={PresentationPage}
      />
      <Route path={paths.presentations.pattern} component={AddContentPage} />
      <Route path={paths.application.pattern} component={ApplicationPage} />
      <Route path={paths.applications.pattern} component={ApplicationsPage} />
      <Route path={paths.account.pattern} component={AccountPage} />
      <Route path={paths.unsubscribe.pattern} component={UnsubscribePage} />
      <Route path={paths.newPlaylist.pattern} component={PlaylistPage} />
      <Route path={paths.editPlaylist.pattern} component={PlaylistPage} />
      <Route
        path={paths.enterpriseWelcome.pattern}
        component={EnterpriseWelcomePage}
      />
      {roles.isMarketplaceEnabled && (
        <Route path={paths.marketplace.pattern} component={MarketplacePage} />
      )}
      {roles.isSiteCorpDashbordEnabled && isEnterpriseUser && (
        <Route path={paths.analytics.pattern} component={AnalyticsPage} />
      )}
      <Route path={paths.home.pattern} component={HomePage} />
      {/* TODO: Legacy changePassword redirect, remove after enterprise migration is complete. */}
      <Route
        path={paths.legacyChangePassword.pattern}
        component={LegacyChangePasswordBackstopPage}
      />
      {/* Redirects */}
      <Route
        path={paths.indexHtml.pattern}
        render={() => <Redirect to={paths.home()} />}
      />
      {/* TODO: 404 page? */}
      <Route render={() => <Redirect to={paths.home()} />} />
    </Switch>
  );
};

export default Routes;
