import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  VirtualFolder,
  Domain,
  Playlist,
  Presentation,
  Theme,
  RecentDeviceError,
} from '@raydiant/api-client-js';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import * as deviceActions from '../../actions/devices';
import * as playlistActions from '../../actions/playlists';
import * as presentationActions from '../../actions/presentations';
import { pollDevicesWithPendingPublish } from '../../sagas/device';
import * as themeActions from '../../actions/themes';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as devicePageActions from '../../pages/DevicesPage/actions';
import { selectLastLoadedDate } from './selectors';

const watchLoadDevicesPage = function* () {
  while (true) {
    yield take(getType(devicePageActions.loadDevicesPage));

    try {
      yield put(devicePageActions.loadDevicesPageAsync.request());
      const [
        devicesFolder,
        deviceErrors,
        domain,
        playlists,
        presentations,
        themes,
      ]: [
        VirtualFolder,
        RecentDeviceError[],
        Domain | null,
        Playlist[],
        Presentation[],
        Theme[],
      ] = yield all([
        call(() => miraClient.getDevicesFolder()),
        // Turn off recent device errors until we can investigate the performance
        // issues happening in TimescaleDB.
        // call(() => miraClient.getRecentDeviceErrors()),
        call(() => []),
        call(() => miraClient.getDomain()),
      ]);
      miraClient.getPlaylists();
      miraClient.getPresentations();
      miraClient.getThemes();

      presentationActions.fetchPresentationsAsync.success(presentations);
      playlistActions.fetchPlaylistsAsync.success(playlists);
      themeActions.getThemesAsync.success(themes);

      yield put(
        batchActions([
          folderActions.fetchVirtualFolderAsync.success({
            id: 'devices',
            ...devicesFolder,
          }),
          deviceActions.fetchRecentDeviceErrorsAsync.success(deviceErrors),
          devicePageActions.loadDevicesPageAsync.success(),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );

      const deviceIds: string[] = [];
      devicesFolder.devices.forEach((device) => {
        if (device.resource.deletedAt === null) {
          deviceIds.push(device.id);
        }
      });

      const lastLoadedDate: string = yield select(selectLastLoadedDate);
      yield call(pollDevicesWithPendingPublish, deviceIds, lastLoadedDate);
    } catch (error: any) {
      logger.error(error);
      yield put(devicePageActions.loadDevicesPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadDevicesPage)]);
