import InputLabel from 'raydiant-elements/core/InputLabel';
import Select from 'raydiant-elements/core/Select';
import { makeStyles } from 'raydiant-elements/styles';
import { Theme } from '@raydiant/api-client-js';
interface ThemeBackgroundScaleProps {
  onChange: (value: Theme['backgroundScale']) => void;
  value: Theme['backgroundScale'];
}

const ThemeBackgroundScale = ({
  onChange,
  value = 'fit',
}: ThemeBackgroundScaleProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <InputLabel>Scaling</InputLabel>
      <Select native={true} onChange={(value) => onChange(value as Theme['backgroundScale'])} value={value}>
        <option value='fit'>Fit</option>
        <option value='fill'>Fill</option>
      </Select>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {},
}));

export default ThemeBackgroundScale;
