import { DeviceAIStatus } from '@raydiant/api-client-js';

import { selectUserHasRole } from '../../selectors/user';
import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';

import useDeviceAISettings from '../../hooks/useDeviceAISettings';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SelectField from 'raydiant-elements/core/SelectField';
import TextField from 'raydiant-elements/core/TextField';
import Switch from 'raydiant-elements/core/Switch';
import Button from 'raydiant-elements/core/Button';

import useStyles from './DeviceAISettings.styles';

import { useMemo, useState } from 'react';

import apiClient from '../../clients/miraClient';
import * as D from '../../clients/mira/types/Device';
import { FlatDeviceAIOption, OptionVal } from './deviceAiSettings.interface';
import {
  unflattenSettings,
  flattenSchema,
  flattenSettings,
  guid,
} from './deviceAiSettingsUtils';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Center from 'raydiant-elements/layout/Center';
import { getDeviceType } from '../../utilities';
import Text from 'raydiant-elements/core/Text';
import Scrollable from 'raydiant-elements/layout/Scrollable';

interface DeviceAISettingsProps {
  device: D.Device;
  visibility?: string;
}

const DeviceAISettings = ({ device }: DeviceAISettingsProps) => {
  const classes = useStyles();

  const userHasRole = useSelector(selectUserHasRole);

  const [aiSettingsSchema, setAiSettingsSchema] = useState<
    Array<FlatDeviceAIOption> | undefined
  >(undefined);
  const [aiSettings, setAiSettings] = useState<
    Record<string, OptionVal> | undefined
  >(undefined);
  const [aiStatus, setAiStatus] = useState<DeviceAIStatus | undefined>(
    undefined,
  );
  const [licenseKey, setLicenseKey] = useState<string>('');
  const [licenseKeyLoading, setLicenseKeyLoading] = useState<boolean>(false);
  const [videoStreamLoading, setVideoStreamLoading] = useState<boolean>(false);
  const [videoStreamURL, setVideoStreamURL] = useState<string | undefined>(
    undefined,
  );

  const deviceId = device.resinUuid;

  const { data, isLoading, isError } = useDeviceAISettings(deviceId);

  const { enqueueSnackbar } = useSnackbar();

  const hackyElemFilter = (elem: FlatDeviceAIOption): boolean =>
    elem.$id.startsWith('#root/ROI');

  const handleUpdateAISettings = async () => {
    if (aiSettings !== undefined) {
      try {
        const resp = await apiClient.setDeviceAISettings(
          deviceId,
          unflattenSettings(aiSettings),
        );
        const flat = flattenSchema(resp.schema.schema.properties);
        setAiSettingsSchema(flat);
        setAiSettings(flattenSettings(flat, resp.settings));
      } catch (error) {
        console.log('ERROR UPDATING AI SETTINGS', error);
        enqueueSnackbar('There was an error updating settings', {
          variant: 'error',
        });
      }
    }
  };

  const handleStartVideoFeed = async () => {
    try {
      const resp = await apiClient.startDeviceAIVideo(deviceId);
      setVideoStreamURL(resp.playback_url);
      setVideoStreamLoading(false);
    } catch (error) {
      console.log('ERROR STARTING VIDEO FEED', error);
      enqueueSnackbar('There was an error starting the video feed', {
        variant: 'error',
      });
    }
  };

  const handleStopVideoFeed = async () => {
    try {
      const resp = await apiClient.stopDeviceAIVideo(deviceId);
      const flat = flattenSchema(resp.schema.schema.properties);
      setAiSettingsSchema(flat);
      setAiSettings(flattenSettings(flat, resp.settings));
      setVideoStreamURL(undefined);
      setVideoStreamLoading(false);
    } catch (error) {
      console.log('ERROR STOPPING VIDEO FEED', error);
      enqueueSnackbar('There was an error stopping the video feed', {
        variant: 'error',
      });
    }
  };

  const renderDeviceAIOption = (option: FlatDeviceAIOption) => {
    if (aiSettings === undefined) {
      return undefined;
    }

    if (option.visibility !== undefined) {
      if (
        option.visibility !== 'Client' &&
        !userHasRole('ai_device_' + option.visibility)
      ) {
        return undefined;
      }
    }

    const _update = (newVal: OptionVal) =>
      setAiSettings({ ...aiSettings, [option.$id]: newVal });
    const _updateAndHandle = (newVal: OptionVal) => {
      _update(newVal);
      handleUpdateAISettings();
    };
    const value = aiSettings[option.$id];
    const lbl = `${option.title} (${option.visibility}) [${value}]`;

    if (option.type === 'string' && option.enum !== undefined) {
      const v = value as string;
      return (
        <SelectField label={lbl} value={v} onChange={_updateAndHandle}>
          {option.enum.map((name) => (
            <option value={name} key={guid()}>
              {name}
            </option>
          ))}
        </SelectField>
      );
    }

    if (option.type === 'boolean') {
      const v = value as boolean;
      return <Switch checked={v} onChange={_updateAndHandle} label={lbl} />;
    }

    if (
      option.type === 'string' ||
      option.type === 'integer' ||
      option.type === 'number'
    ) {
      const v = value as string;
      return (
        <TextField
          label={lbl}
          value={v}
          onChange={_update}
          onBlur={handleUpdateAISettings}
        />
      );
    }

    return undefined;
  };

  const renderDeviceAIOptionGroup = (
    options: Array<FlatDeviceAIOption>,
    title?: string,
  ) => {
    return (
      <ul className={classes.aiSettingList}>
        {title && (
          <li className={classes.aiSetting}>
            <Heading size={5} overline gutterBottom>
              {title}
            </Heading>
          </li>
        )}
        {options.map((ent) => {
          const res = renderDeviceAIOption(ent);
          if (res) {
            return (
              <li className={classes.aiSetting} key={guid()}>
                {res}
              </li>
            );
          }
          return undefined;
        })}
      </ul>
    );
  };

  const renderVideoFeedSettings = () => {
    const toggleVideoStream = () => {
      setVideoStreamLoading(true);
      if (videoStreamURL === undefined) {
        handleStartVideoFeed();
      } else {
        handleStopVideoFeed();
      }
    };

    return (
      <div className={classes.section}>
        <Heading size={5} overline gutterBottom>
          {'Video feed'}
        </Heading>
        <video className={classes.aiVideoStream}>
          <source src={videoStreamURL} />
        </video>
        <Button
          fullWidth
          label={
            videoStreamURL === undefined
              ? 'Start Video Streaming'
              : 'Stop Video Streaming'
          }
          disabled={videoStreamLoading}
          onClick={toggleVideoStream}
          color={'primary'}
        />
        {aiSettingsSchema &&
          renderDeviceAIOptionGroup(aiSettingsSchema.filter(hackyElemFilter))}
      </div>
    );
  };

  const renderOtherSettings = () => {
    return (
      aiSettingsSchema && (
        <div className={classes.section}>
          <Heading size={5} overline gutterBottom>
            {'Settings'}
          </Heading>
          {renderDeviceAIOptionGroup(
            aiSettingsSchema.filter((el) => !hackyElemFilter(el)),
          )}
        </div>
      )
    );
  };

  const renderLicenceKey = () => {
    return (
      <div className={classes.section}>
        <div className={classes.licenseKey}>
          <TextField
            label={'Please enter a valid License Key'}
            value={licenseKey}
            onChange={setLicenseKey}
          />
        </div>
        <Button
          fullWidth
          label={'Save License Key'}
          disabled={licenseKeyLoading}
          icon={licenseKeyLoading ? <SettingsPowerIcon /> : undefined}
          onClick={() => {
            setLicenseKeyLoading(true);
            const handleLicenseKeySet = async () => {
              try {
                const resp = await apiClient.setDeviceAILicenseKey(
                  deviceId,
                  licenseKey,
                );
                setLicenseKeyLoading(false);
                setAiStatus(resp.status);
              } catch (error) {
                console.log('ERROR SETTING LICENSE KEY', error);
                enqueueSnackbar('There was an error setting the license key', {
                  variant: 'error',
                });
                setLicenseKeyLoading(false);
              }
            };
            handleLicenseKeySet();
          }}
          color={'primary'}
        />
      </div>
    );
  };

  const flattenedAiSettings = useMemo(() => {
    if (data) {
      const flattened = flattenSchema(data.schema.schema.properties);
      setAiSettingsSchema(flattened);
      setAiSettings(flattenSettings(flattened, data.settings));
      setAiStatus(data.status);

      return flattened;
    }
  }, [data]);

  const renderSettings = () => {
    if (isLoading) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    if (isError) {
      return <p>Error</p>;
    }

    if (data && flattenedAiSettings) {
      if (aiStatus?.LicenceKey_Status !== 'VALID') {
        return renderLicenceKey();
      } else {
        return (
          <>
            {aiSettings && renderVideoFeedSettings()}
            {aiSettingsSchema && renderOtherSettings()}
          </>
        );
      }
    }
  };

  const deviceType = getDeviceType(device);

  return (
    <>
      <PaperModal.Body>
        <Column>
          <div>
            <Heading className={classes.title}>
              <Text editable value={device.name} />
            </Heading>
            <Heading size={5}>AI Settings</Heading>
            {deviceType && (
              <Heading size={5} className={classes.type}>
                {deviceType}
              </Heading>
            )}
          </div>
        </Column>
        <br />
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
            <Column>
              {renderSettings()}
            </Column>
        </PaperModal.Body>
      </Scrollable>
    </>
  );
};

export default DeviceAISettings;
