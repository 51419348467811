import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import { ApiClientError } from '@raydiant/api-client-js';

import CxBackground from '../assets/marketplace-cx.png';
import ExBackground from '../assets/marketplace-ex.png';
import DxBackground from '../assets/marketplace-dx.png';

export interface MarketPlaceTile {
  type: 'cx' | 'dx' | 'ex';
  heading: string;
  body: string;
  backgroundImage: string;
  buttonColor: 'progress' | 'default' | 'primary';
}

const marketPlaceData: MarketPlaceTile[] = [
  {
    body: 'Decrease content management &\ndeployment costs.',
    backgroundImage: CxBackground,
    buttonColor: 'progress',
    heading: 'Rules & Tags',
    type: 'cx',
  },
  {
    body: 'Build custom apps for digital\nsignage and kiosks',
    backgroundImage: DxBackground,
    buttonColor: 'default',
    heading: 'Developer Platform',
    type: 'dx',
  },
  {
    body: 'Creating a more connected\nemployee experience',
    backgroundImage: ExBackground,
    buttonColor: 'primary',
    heading: 'Employee Experience\nfor Brick & Mortar',
    type: 'ex',
  },
];

const shuffleArray = (array: MarketPlaceTile[]): MarketPlaceTile[] => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));

    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export default function useMarketPlaceTiles() {
  const query = useProtectedQuery<MarketPlaceTile[], ApiClientError>(
    keys.marketPlaceTiles(),
    () => shuffleArray(marketPlaceData),
  );

  return query;
}
