const FONT_WEIGHTS: string[] = [
  'black',
  'blackitalic',
  'bold',
  'bolditalic',
  'extra',
  'extrabold',
  'extrabolditalic',
  'extraitalic',
  'extralight',
  'extralightitalic',
  'italic',
  'light',
  'lightitalic',
  'medium',
  'mediumitalic',
  'regular',
  'semi',
  'semibold',
  'semibolditalic',
  'semiitalic',
  'thin',
  'thinitalic',
];

export const getFontWeight = (file: File): string => {
  const fontWeight = FONT_WEIGHTS.find((fontWeight) =>
    matchesFontWeights(file, fontWeight),
  );

  return fontWeight ? parseFontWeight(fontWeight) : 'regular';
};

export const matchesFontWeights = (file: File, fontWeight: string) =>
  fileName(file).match(new RegExp(`\\b${fontWeight}\\b`, 'gmi'));

export const parseFontName = (file: File) => {
  const fontWeightMatch = matchesFontWeights(file, getFontWeight(file));

  if (fontWeightMatch && fontWeightMatch[0]) {
    const name = fileName(file).replace(fontWeightMatch[0], '');

    return name.endsWith('-') ? name.slice(0, -1) : name;
  }

  return fileName(file);
};

export const contentType = (file: File) => `font/${fileExtension(file)}`;

export const fileName = (file: File) =>
  file.name.replace(`.${fileExtension(file)}`, '');

export const parseFontWeight = (string: string) =>
  string.replace(/ |-|_/g, '').toLowerCase();

const fileExtension = (file: File) => file.name.split('.').pop();
