import { ApiClientError } from '@raydiant/api-client-js';
import { Font } from '@raydiant/api-client-js/build/types/Font';
import { keys } from '../queryClient';
import useProtectedQuery from './useProtectedQuery';
import raydiant from '../clients/miraClient';
import { useMemo } from 'react';
import {
  fontFamilies,
  FontFamily,
  injectFontFaces,
} from '../components/TextStyleField/fontFamilies';
import { titleCase } from '../utilities/stringUtils';

export default function useFonts() {
  const query = useProtectedQuery<Font[], ApiClientError>(keys.fonts(), () =>
    raydiant.getFonts(),
  );

  const groupedFonts = useMemo<FontFamily[]>(() => {
    const fontGroups = groupByFontName(query.data ?? []);
    const fontWeights = Object.keys(fontGroups).map((name) => {
      const item = fontGroups[name];
      const fontWeightGroup = groupByFontWeight(item);

      return {
        default: 'Regular',
        fonts: Object.keys(fontWeightGroup).map((name) => ({
          name,
          url: fontWeightGroup[name].url,
        })),
        id: name,
        name: name,
      };
    });

    const fonts = [...fontFamilies, ...fontWeights];

    injectFontFaces(fonts);

    return fonts;
  }, [query.data]);

  return {
    ...query,
    data: groupedFonts,
  };
}

const groupByFontName = (array: Font[]): { [key: string]: Font[] } =>
  array.reduce((acc: any, item: Font) => {
    if (acc[item.name]) {
      acc[item.name].push(item);
    } else {
      acc[item.name] = [item];
    }

    return acc;
  }, {});

const getUrl = (font: Font): string => font.fileUpload.url;

const groupByFontWeight = (array: Font[]) =>
  array.reduce((acc: any, item: Font) => {
    if (acc[item.name]) {
      acc[titleCase(item.weight)].push({
        name: titleCase(item.name),
        url: getUrl(item),
      });
    } else {
      acc[titleCase(item.weight)] = {
        name: titleCase(item.name),
        url: getUrl(item),
      };
    }

    return acc;
  }, {});
