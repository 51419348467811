import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import { FC } from 'react';
import history from '../../history';
import * as paths from '../../routes/paths';

import Center from 'raydiant-elements/layout/Center';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { MarketPlaceTile } from '../../hooks/useMarketPlaceTiles';

interface MarketplaceTileProps {
  data: MarketPlaceTile;
  isLoading: boolean;
}

interface StyleProps {
  backgroundImage: string;
  buttonColor:
    | 'light'
    | 'default'
    | 'progress'
    | 'primary'
    | 'destructive'
    | undefined;
}

const MarketplaceTile: FC<MarketplaceTileProps> = ({ data, isLoading }) => {
  const classes = useStyles(data);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress size={30} />
      </Center>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <Heading size={1} weight={600} className={classes.heading}>
          {data.heading}
        </Heading>
        <div className={classes.textWrapper}>
          <Text className={classes.text} xlarge>
            {data.body}
          </Text>
        </div>

        <Button
          label="Let's go!"
          color={data.buttonColor}
          onClick={() => history.push(paths.marketplace())}
          className={classes.button}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return createStyles({
    wrapper: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      flex: 1,
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(9),
      backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&>:nth-child(1)': {
        justifyContent: 'flex-start',
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    button: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    heading: {
      color: '#ffffff',
      whiteSpace: 'pre',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      color: '#ffffff',

      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    text: {
      whiteSpace: 'pre',
    },
  });
});

export default MarketplaceTile;
