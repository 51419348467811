import Page from '../../components/Page';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    iframe: {
      border: 0,
      height: 'calc(100vh - 69px)', //height of the navbar
      width: '100%',
    },
  });
});

const AnalyticsPage = () => {
  const classes = useStyles();

  return (
    <Page>
      <Scrollable>
        <iframe
          className={classes.iframe}
          src="https://datastudio.google.com/embed/reporting/ade87913-36e6-49fd-8729-7d77485aac73/page/yNf8B"
          title="SiteCorp Dashboard"
        />
      </Scrollable>
    </Page>
  );
};

export default AnalyticsPage;
