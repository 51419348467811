import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { UpdatePaymentMethodResponse } from '@raydiant/api-client-js/build/types/PaymentMethod';
import Button from 'raydiant-elements/core/Button';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Center from 'raydiant-elements/layout/Center';
import { FC, useState } from 'react';
import apiClient from '../../../clients/miraClient';
import UpdatePaymentMethodConfirmDialog from './UpdatePaymentMethodConfirmDialog';
import UpdatePaymentMethodErrorDialog from './UpdatePaymentMethodErrorDiaglog';
import UpdatePaymentMethodSuccessDialog from './UpdatePaymentMethodSuccessDialog';

interface UpdatePaymentMethodProps {
  defaultStatus?: 'idle';
}

const hasErrors = (response: UpdatePaymentMethodResponse) =>
  response.some((item) => item.errorCode !== "Success");

const UpdatePaymentMethod: FC<UpdatePaymentMethodProps> = ({
  defaultStatus = 'idle',
}) => {
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [status, setStatus] = useState<
    'idle' | 'error' | 'loading' | 'success'
  >(defaultStatus);
  const [errorCodes, setErrorCodes] = useState<string | null>();

  const showIdle = status === 'idle';
  const showError = status === 'error';
  const showSuccess = status === 'success';
  const showLoading = status === 'loading';

  const updatePaymentMethod = async () => {
    setStatus('loading');

    try {
      const response = await apiClient.sendUpdatePaymentMethodEmail();

      if (hasErrors(response)) {
        setErrorCodes(response.map((item) => item.errorCode).join(','));
        setStatus('error');
      } else {
        setStatus('success');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  const handleOpenDialog = () => {
    setOpenDialogue(true);
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };

  return (
    <>
      <Button
        color="default"
        label="Update payment method"
        fullWidth
        onClick={handleOpenDialog}
      />
      <InputHelperText indent>
        Reset your payment method
      </InputHelperText>
      <Dialog
        open={openDialogue}
        TransitionProps={{
          onExited: () => {
            setStatus('idle');
          },
        }}
      >
        {showLoading && (
          <DialogContent>
            <DialogContentText>
              <Center>
                <CircularProgress size={30} />
              </Center>
            </DialogContentText>
          </DialogContent>
        )}
        {showIdle && (
          <UpdatePaymentMethodConfirmDialog
            handleCancel={handleClose}
            handleConfirm={updatePaymentMethod}
          />
        )}
        {showSuccess && (
          <UpdatePaymentMethodSuccessDialog handleConfirm={handleClose} />
        )}
        {showError && (
          <UpdatePaymentMethodErrorDialog
            errorCode={errorCodes ?? ''}
            handleConfirm={handleClose}
          />
        )}
      </Dialog>
    </>
  );
};

export default UpdatePaymentMethod;
