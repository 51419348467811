import { FC, useEffect, useState, useRef } from 'react';
import useStyles from './TagInput.styles';
import Row from 'raydiant-elements/layout/Row';
import TagIcon from 'raydiant-elements/icons/Tag';
export interface TagInputTimeTypeProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TagInputTimeType: FC<TagInputTimeTypeProps> = ({
  label,
  value: initialValue,
  onChange,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState<string>(initialValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={classes.root}>
      <Row halfMargin center>
        <TagIcon className={classes.icon} />
        <div className={classes.tag}>
          <span>{label}:</span>
          &nbsp;
          <input
            ref={inputRef}
            type="time"
            className={classes.tagTimeInput}
            value={value}
            onChange={e => {
              setValue(e.target.value);
            }}
            onBlur={() => onChange(value)}
          />
        </div>
      </Row>
    </div>
  );
};

export default TagInputTimeType;
