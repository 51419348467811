import { FC, MouseEventHandler } from 'react';
import Row from 'raydiant-elements/layout/Row';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistItemMoreActionsButton from './PlaylistItemMoreActionsButton';
import IconButton from '@material-ui/core/IconButton';
import TagIcon from 'raydiant-elements/icons/Tag';
import useCurrentUser from '../../hooks/useCurrentUser';
interface PlaylistItemPresentationActionsProps {
  moreActionsOpen?: boolean;
  onMoreClick?: MouseEventHandler<HTMLButtonElement>;
  onTagsClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlaylistItemPresentationActions: FC<PlaylistItemPresentationActionsProps> = ({
  moreActionsOpen,
  onMoreClick,
  onTagsClick,
}) => {
  const { classes } = usePlaylistPageContext();

  const { data: currentUser } = useCurrentUser();
  const showRulesAndTags = !!currentUser?.domainId;

  return (
    <Row inline halfMargin center className={classes.playlistItemActions}>
      {onTagsClick && showRulesAndTags && (
        <Row
          inline
          halfMargin
          center
          className={classes.playlistItemActionsSecondary}
        >
          <div>
            <IconButton
              className={classes.playlistItemButton}
              size="small"
              onClick={onTagsClick}
            >
              <TagIcon color="inherit" style={{ padding: 2 }} />
            </IconButton>
          </div>
        </Row>
      )}
      {onMoreClick && (
        <div>
          <PlaylistItemMoreActionsButton
            selected={moreActionsOpen}
            onClick={onMoreClick}
          />
        </div>
      )}
    </Row>
  );
};

export default PlaylistItemPresentationActions;
