import { FC } from 'react';
import moment from 'moment-timezone';
import SelectField from 'raydiant-elements/core/SelectField';

interface TimezoneSelectProps {
  label: string;
  onChange: (value: string) => void;
  value: string;
}

const getFormattedTimezoneNames = () => {
  const getOffset = (tz: string) => moment.tz(tz).format('Z');
  const byOffset = (a: string, b: string) =>
    parseInt(getOffset(a), 10) > parseInt(getOffset(b), 10) ? 1 : -1;

  return moment.tz
    .names()
    .filter(getOffset)
    .sort(byOffset)
    .map((tz) => ({ value: tz, label: `(GMT${getOffset(tz)}) ${tz}` }));
};

const TimeZoneSelect: FC<TimezoneSelectProps> = ({
  label,
  onChange,
  value,
}) => {
  return (
    <SelectField label={label} value={value} onChange={onChange}>
      {getFormattedTimezoneNames().map(({ value, label }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </SelectField>
  );
};

export default TimeZoneSelect;
