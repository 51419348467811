import { makeStyles, createStyles } from '../../components/withStyles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    checkmark: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      zIndex: 9999,
      color: '#28d972',
      position: 'relative',
      bottom: '-0.75rem',
      left: '0.75rem',
    },
  });
});
