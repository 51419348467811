import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as appActions from '../../actions/applications';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import {
  VirtualFolder,
  Folder,
  Application,
  Domain,
} from '@raydiant/api-client-js';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

type LoadLibraryPageAction = ReturnType<typeof actions.loadLibraryPage>;

const loadPageForTopLevelLibrary = function* () {
  const [libraryFolder, applications, domain]: [
    VirtualFolder,
    Application[],
    Domain | null,
  ] = yield all([
    call(() => miraClient.getLibraryFolder()),
    call(() => miraClient.getApplications()),
    call(() => miraClient.getDomain()),
  ]);

  yield put(
    batchActions([
      folderActions.fetchVirtualFolderAsync.success({
        id: 'library',
        ...libraryFolder,
      }),
      appActions.fetchApplicationsAsync.success(applications),
      actions.loadLibraryPageAsync.success(),
      ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
    ]),
  );
};

const loadPageForFolder = function* (folderId: string) {
  const [folder, applications, domain]: [Folder, Application[], Domain | null] =
    yield all([
      call(() => miraClient.getFolder(folderId)),
      call(() => miraClient.getApplications()),
      call(() => miraClient.getDomain()),
    ]);

  yield put(
    batchActions([
      folderActions.fetchFolderAsync.success(folder),
      appActions.fetchApplicationsAsync.success(applications),
      actions.loadLibraryPageAsync.success(),
      ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
    ]),
  );
};

const loadLibraryFolder = function* () {
  const libraryFolder: VirtualFolder = yield call(() =>
    miraClient.getLibraryFolder(),
  );

  yield put(
    folderActions.fetchVirtualFolderAsync.success({
      id: 'library',
      ...libraryFolder,
    }),
  );
};

const watchLoadLibraryPage = function* () {
  while (true) {
    const action: LoadLibraryPageAction = yield take(
      getType(actions.loadLibraryPage),
    );
    const { folderId } = action.payload;

    try {
      yield put(actions.loadLibraryPageAsync.request());

      if (folderId) {
        // When loading a folder on page load, Load the entire library in parallel but don't
        // make the loading spinner depend on it.
        yield all([loadPageForFolder(folderId), loadLibraryFolder()]);
      } else {
        yield loadPageForTopLevelLibrary();
      }
    } catch (error: any) {
      logger.error(error);
      yield put(actions.loadLibraryPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadLibraryPage)]);
