import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import { FC } from 'react';

type UpdatePaymentMethodSuccessDialogProps = {
  handleConfirm: () => void;
};

const UpdatePaymentMethodSuccessDialog: FC<
  UpdatePaymentMethodSuccessDialogProps
> = ({ handleConfirm }) => {
  return (
    <>
      <DialogTitle>Check Your Inbox</DialogTitle>
      <DialogContent>
        <DialogContentText>
          An email detailing how to update your payment method has been sent to
          your inbox.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default UpdatePaymentMethodSuccessDialog;
