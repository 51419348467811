import SettingsIcon from '@material-ui/icons/Settings';
import HttpsIcon from '@material-ui/icons/Https';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import Heading1 from 'raydiant-elements/typography/Heading1';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { stopPropagation } from 'raydiant-elements/helpers';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';
import { getDeviceType } from '../../utilities';
import DeviceStatus from './DeviceStatus';
import { ContentWarning } from './getDeviceContentWarning';

interface DeviceInfoProps {
  device: Device;
  contentWarning: ContentWarning | null;
  deviceErrors: RecentDeviceError[];
  lastLoadedDate: string;
  isEditable?: boolean;
  onSettingsClick: () => void;
  onAiSettingsClick: () => void;
  openStatusPopover: boolean;
  onOpenStatusPopover: (open: boolean) => void;
}

const DeviceInfo = ({
  device,
  contentWarning,
  deviceErrors,
  isEditable,
  lastLoadedDate,
  onSettingsClick,
  onAiSettingsClick,
  openStatusPopover,
  onOpenStatusPopover,
}: DeviceInfoProps) => {
  const classes = useStyles();
  const deviceType = getDeviceType(device);

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        {deviceType && <div className={classes.type}>{deviceType}</div>}
        <Heading1 className={classes.name}>
          <span title={device.name}>{device.name}</span>
        </Heading1>
        {isEditable ? (
          <button
            className={classes.settings}
            onClick={stopPropagation(onSettingsClick)}
            disabled={!isEditable}
          >
            <SettingsIcon fontSize="inherit" />
          </button>
        ) : (
          <button className={classes.settings} disabled>
            <HttpsIcon fontSize="inherit" />
          </button>
        )}
      </div>
      <DeviceStatus
        muted
        device={device}
        contentWarning={contentWarning}
        deviceErrors={deviceErrors}
        lastLoadedDate={lastLoadedDate}
        openStatusPopover={openStatusPopover}
        onOpenStatusPopover={onOpenStatusPopover}
        onAiSettingsClick={onAiSettingsClick}
        showAiStatus={true}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2, 0),
      paddingLeft: theme.spacing(3.5),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        paddingRight: 0, // Aligns the setting icon
        width: '100%',
      },
    },

    offlineIcon: {
      color: theme.palette.error.main,
      backgroundColor: '#fff',
      borderRadius: 100,
    },

    info: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      position: 'relative',
      fontSize: 24, // Sets settings icon size.
    },

    name: {
      ...textTruncate(),
      color: 'inherit',
      width: theme.spacing(36),
      fontWeight: 400,
      fontSize: `${18 / theme.typography.fontSize}rem`, // So far a one-off font size.

      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },

    type: {
      fontSize: theme.fontSizes.xxs,
      color: 'inherit',
      opacity: 0.6,
      fontWeight: 600,
      letterSpacing: 0.06,
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: -12,
      left: 2,

      [theme.breakpoints.down('xs')]: {
        top: -8,
      },
    },

    settings: {
      ...buttonReset(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.6,
      padding: theme.spacing(0, 1),

      '&:disabled': {
        opacity: 0.8,
        color: 'inherit',
        cursor: 'not-allowed',
      },

      '&:hover': {
        opacity: 0.8,
      },
    },
  });
});

export default DeviceInfo;
