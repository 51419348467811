import IconButton from '@material-ui/core/IconButton';
import MenuSharp from '@material-ui/icons/Menu';
import Menu from 'raydiant-elements/core/Menu';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import history from '../../history';
import * as routes from '../../routes';
import NavLink from '../NavLink';
import { FC, useState } from 'react';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';
import { useSelector } from 'react-redux';
import { selectIsEnterpriseUser } from '../../selectors/user';
interface NavigationProps {
  title?: string;
  backTo?: string;
}

const Navigation: FC<NavigationProps> = ({ backTo, title }) => {
  const [open, setOpen] = useState<boolean>(false);

  const roles = useCurrentUserRoles();
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const serviceCloudChatButton = document.querySelector("#helpButtonSpan > span.message") as HTMLElement;

  const openChat = () => serviceCloudChatButton?.click();

  const renderDesktopMenu = () => {
    return (
      <Menu>
        <Menu.Header>
          <Menu.Logo
            width={80}
            href={routes.home()}
            onClick={() => history.push(routes.home())}
          />
          <div style={{ flex: 1, maxWidth: 120 }} />
          <NavLink to={routes.screens()} path={routes.screens.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Screens" />}
          </NavLink>
          <NavLink to={routes.library()} path={routes.library.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Library" />}
          </NavLink>
          {roles.isMarketplaceEnabled && (
            <NavLink
              to={routes.marketplace()}
              path={routes.marketplace.pattern}
            >
              {(navLinkProps) => (
                <Menu.Item {...navLinkProps} label="Marketplace" />
              )}
            </NavLink>
          )}
          {roles.isSiteCorpDashbordEnabled && isEnterpriseUser && (
            <NavLink to={routes.analytics()} path={routes.analytics.pattern}>
              {(navLinkProps) => (
                <Menu.Item {...navLinkProps} label="Analytics" />
              )}
            </NavLink>
          )}
          <NavLink to={routes.account()} path={routes.account.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Account" />}
          </NavLink>
          <Spacer />
          {!roles.isServiceCloudChatEnabled && (
            <Menu.Item
              classes={{ root: 'intercom-live-support' }}
              label="Live Support"
              onClick={() => {}}
            />
          )}
          {roles.isServiceCloudChatEnabled && (
            <Menu.Item
              classes={{ root: 'live-support' }}
              label="Live Support"
              onClick={openChat}
            />
          )}
          <Menu.Item
            href="https://raydiant.com/help"
            label="Knowledge Base"
            target="_blank"
          />
        </Menu.Header>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Menu>
        <Menu.Header>
          {backTo ? (
            <Menu.Back href={backTo} onClick={() => history.push(backTo)} />
          ) : (
            <Menu.Logo
              square
              href={routes.home()}
              onClick={() => history.push(routes.home())}
            />
          )}
          <Spacer />
          {title && <Menu.Title label={title} />}
          <IconButton
            color="inherit"
            edge="end"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <MenuSharp color="inherit" />
          </IconButton>
        </Menu.Header>
        <Menu.Drawer open={open}>
          <NavLink to={routes.screens()} path={routes.screens.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Screens" />
            )}
          </NavLink>
          <NavLink to={routes.library()} path={routes.library.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Library" />
            )}
          </NavLink>
          {roles.isMarketplaceEnabled && (
            <NavLink
              to={routes.marketplace()}
              path={routes.marketplace.pattern}
            >
              {(navLinkProps) => (
                <Menu.DrawerItem {...navLinkProps} label="Marketplace" />
              )}
            </NavLink>
          )}
          {roles.isSiteCorpDashbordEnabled && isEnterpriseUser && (
            <NavLink to={routes.analytics()} path={routes.account.pattern}>
              {(navLinkProps) => (
                <Menu.DrawerItem {...navLinkProps} label="Analytics" />
              )}
            </NavLink>
          )}
          <NavLink to={routes.account()} path={routes.account.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Account" />
            )}
          </NavLink>
          {!roles.isServiceCloudChatEnabled && (
            <Menu.DrawerItem
              classes={{ root: 'intercom-live-support' }}
              label="Live Support"
              onClick={() => {}}
            />
          )}
          {roles.isServiceCloudChatEnabled && (
            <Menu.DrawerItem
              classes={{ root: 'intercom-live-support' }}
              label="Live Support"
              onClick={openChat}
            />
          )}
          <Menu.DrawerItem
            href="https://raydiant.com/help"
            label="Knowledge Base"
            target="_blank"
          />
        </Menu.Drawer>
      </Menu>
    );
  };

  return (
    <>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default Navigation;
