import 'core-js/es/array/find';
import 'core-js/es/array/find-index';
import 'core-js/es/array/includes';
import 'core-js/es/array/is-array';
import 'core-js/es/array/keys';
import 'core-js/es/array/fill';
import 'core-js/es/object/entries';
import 'core-js/es/object/keys';
import 'core-js/es/object/values';
import 'core-js/es/string/includes';
import 'core-js/es/string/repeat';
import 'core-js/es/string/ends-with';
import 'core-js/es/weak-map';
import 'core-js/web/url-search-params';
import 'react-app-polyfill/ie11';

import App from 'raydiant-elements/core/App';
import { StylesProvider } from '@material-ui/core/styles';
import ThemeProvider from 'raydiant-elements/core/ThemeProvider';
import theme from 'raydiant-elements/theme';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { DndProvider } from 'react-dnd';
import isTouchDevice from 'is-touch-device';
import Html5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { PersistGate } from 'redux-persist/integration/react';
import * as Immer from 'immer';
import SnackbarProvider from './components/SnackbarProvider';
import ErrorBoundary from './components/ErrorBoundary';
import './global.css';
import history from './history';
import logger from './logger';
import Routes from './routes';
import loadStore from './store';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from './config';

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [new BrowserTracing()],
  debug: true,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// For IE11.
Immer.enableES5();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default cache all queries for 10 seconds, this assumes we are using
      // optimistic updates or manually invalidating if we need to refetch a query
      // before the cache expires.
      staleTime: 10000,
      // Always refetch on mount regardless of stale time. This helps with keeping react-query
      // in sync with redux. We could potentially remove this when more pages (like the presentation builder)
      // are migrated to react-query and we explicitly invalidate queries.
      refetchOnMount: 'always',
      // Don't refetch on focus by default.
      refetchOnWindowFocus: false,
      // Don't rerender when data becomes stale by default.
      notifyOnChangePropsExclusions: ['isStale'],
    },
  },
});

loadStore().then(({ store, persistor }) => {
  ReactDOM.render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools /> */}
        <Provider store={store}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <DndProvider
                backend={isTouchDevice() ? TouchBackend : Html5Backend}
                options={{ delayTouchStart: 150 }}
              >
                <SnackbarProvider>
                  <PersistGate loading={null} persistor={persistor}>
                    <Router history={history}>
                      <App color="grey">
                        <Routes />
                      </App>
                    </Router>
                  </PersistGate>
                </SnackbarProvider>
              </DndProvider>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
});

logger.info(`Page loaded: ${window.location.pathname}`);

// Remove the old Dashboard's service worker.
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

