import * as React from 'react';
import { Device, Playlist, Profile } from '@raydiant/api-client-js';
import config from '../../config';

const liteSupportedAppIds = [
  config.flyersAppId,
  config.picturesAppId,
  config.youtubeAppId,
  config.cloverAppId,
];

export interface ContentWarning {
  severity: 'warning' | 'error';
  message: React.ReactNode;
  priority: number;
}

export type ContentWarningMap = Record<
  | 'noContent'
  | 'unsupportedContent'
  | 'multiplePresentations'
  | 'scheduledContent',
ContentWarning>

export const BrightSignWarnings: ContentWarningMap = {
  noContent: {
    severity: 'error',
    message:
    'BrightSign only supports Pictures, Flyers, YouTube and Clover',
    priority: 100,
  },
  unsupportedContent: {
    severity: 'warning',
    message:
    'BrightSign only supports Pictures, Flyers, YouTube and Clover',
    priority: 30,
  },
  multiplePresentations: {
    severity: 'warning',
    message: 'BrightSign does not support more than one presentation',
    priority: 20,
  },
  scheduledContent: {
    severity: 'warning',
    message: 'BrightSign does not support scheduled content',
    priority: 10,
  }
};

export const warningsByPriority: Record<string, ContentWarningMap> = {
  lite: {
    noContent: {
      severity: 'error',
      message:
      'ScreenRay Lite only supports Pictures, Flyers, YouTube and Clover',
      priority: 100,
    },
    unsupportedContent: {
      severity: 'warning',
      message:
      'ScreenRay Lite only supports Pictures, Flyers, YouTube and Clover',
      priority: 30,
    },
    multiplePresentations: {
      severity: 'warning',
      message: 'ScreenRay Lite does not support more than one presentation',
      priority: 20,
    },
    scheduledContent: {
      severity: 'warning',
      message: 'ScreenRay Lite does not support scheduled content',
      priority: 10,
    },
  },
  Brightsign: BrightSignWarnings,
  BrightSign: BrightSignWarnings,
  Poly: {
    noContent: {
      severity: 'error',
      message:
      'This free version of Raydiant only supports Pictures, Flyers and YouTube',
      priority: 100,
    },
    unsupportedContent: {
      severity: 'warning',
      message:
      'This free version of Raydiant only supports Pictures, Flyers and YouTube',
      priority: 30,
    },
    multiplePresentations: {
      severity: 'warning',
      message:
      'This free version of Raydiant does not support more than one presentation',
      priority: 20,
    },
    scheduledContent: {
      severity: 'warning',
      message: 'This free version of Raydiant does not support scheduled content',
      priority: 10,
    },
  },
};

const setWarningByPriority = (
  current: ContentWarning | null,
  next: ContentWarning,
) => {
  // Set warning if there's no current warning or current warning has a lower priority.
  if (!current || current.priority < next.priority) {
    return next;
  }
  return current;
};

export default function getDeviceContentWarning(
  device: Device,
  playlist: Playlist,
  profile: Profile,
): ContentWarning | null {
  let contentWarning: ContentWarning | null = null;

  const isUpgradedPolyDevice =
    profile.polyServiceLevel === 'full' && device.model === 'Poly';

  if (
    device.isLite &&
    device.model !== 'SecondScreen' &&
    !isUpgradedPolyDevice
  ) {
    let supportedAppCount = 0;
    let unsupportedAppCount = 0;

    const modelWarnings = warningsByPriority[device.model] || warningsByPriority['lite']


    for (const item of playlist.items) {
      if (item.presentation) {
        // Skip deleted presentations
        if (item.presentation.resource.deletedAt !== null) {
          continue;
        }

        // Check for unsupported content.
        const isSupportedContent = liteSupportedAppIds.includes(
          // TODO: Use the new application name field (not currently returned by the API)
          // instead of app ids.
          item.presentation.applicationId,
        );

        if (isSupportedContent) {
          supportedAppCount += 1;
        } else {
          unsupportedAppCount += 1;
        }

        // Check for multiple presentations.
        if (supportedAppCount > 1) {
          contentWarning = setWarningByPriority(
            contentWarning,
            modelWarnings.multiplePresentations,
          );
        }
      } else if (item.playlist) {
        // Skip deleted playlists
        if (item.playlist.resource.deletedAt) {
          continue;
        }

        // Skip non-scheduled playlists
        if (!item.playlist.endDatetime) {
          continue;
        }

        // Skip scheduled content that has ended.
        const endDate = new Date(item.playlist.endDatetime);
        const now = new Date();
        if (!playlist.recurrenceRule && +endDate < +now) {
          continue;
        }

        contentWarning = setWarningByPriority(
          contentWarning,
	  modelWarnings.scheduledContent,
        );
      }
    }

    // Override warning with error if there's no supported content in playlist.
    if (supportedAppCount === 0) {
      contentWarning = setWarningByPriority(contentWarning, modelWarnings.noContent);
    }

    // Show warning for playlist with unsupported content
    if (unsupportedAppCount !== 0) {
      contentWarning = setWarningByPriority(
        contentWarning,
	modelWarnings.unsupportedContent,
      );
    }
  }

  return contentWarning;
}
