import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsRaydiantSuperAdmin,
  selectIsCustomerSupport,
  selectIsDeveloper,
  selectIsAppReviewer,
  selectIsSiteCorpDashbordEnabled,
  selectIsMarketplaceEnabled,
  selectIsServiceCloudChatEnabled,
} from '../selectors/user';

export default function useCurrentUserRoles() {
  // We are using useSelector here to re-use the state that's in redux, which is populated
  // on page load in actions/user.ts. Ideally we would use react-query to fetch and cache the
  // current user's roles but to avoid two fetches we will use the redux state until we can
  // refactor more pages to use react-query instead.
  const raydiantSuperadmin = useSelector(selectIsRaydiantSuperAdmin);
  const customerSupport = useSelector(selectIsCustomerSupport);
  const developer = useSelector(selectIsDeveloper);
  const appReviewer = useSelector(selectIsAppReviewer);
  const isSiteCorpDashbordEnabled = useSelector(
    selectIsSiteCorpDashbordEnabled,
  );
  const isMarketplaceEnabled = useSelector(selectIsMarketplaceEnabled);
  const isServiceCloudChatEnabled = useSelector(
    selectIsServiceCloudChatEnabled,
  );

  // Convert role list to a map for easy lookup.
  const roles = useMemo(() => {
    return {
      raydiantSuperadmin,
      customerSupport,
      developer,
      appReviewer,
      isSiteCorpDashbordEnabled,
      isMarketplaceEnabled,
      isServiceCloudChatEnabled,
    };
  }, [
    raydiantSuperadmin,
    customerSupport,
    developer,
    appReviewer,
    isSiteCorpDashbordEnabled,
    isMarketplaceEnabled,
    isServiceCloudChatEnabled,
  ]);

  return roles;
}
