import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import xor from 'lodash/xor';
import Paper from 'raydiant-elements/core/Paper';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import Row from 'raydiant-elements/layout/Row';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Title from 'raydiant-elements/typography/Title';
import Text from 'raydiant-elements/typography/Text';
import { FC, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import LibraryDragLayer from '../../components/LibraryDragLayer';
import BreadcrumbDivider from '../../components/BreadcrumbDivider';
import LibraryTree from '../../components/LibraryTree';
import * as F from '../../clients/mira/types/Folder';
import {
  selectUserProfile,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import {
  selectLibraryByOwner,
  selectFoldersById,
  selectFolderStatusById,
} from '../../selectors/v2/folders';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import * as folderActions from '../../actions/folders';
import * as presentationActions from '../../actions/presentations';
import {
  sortFolder,
  searchPresentations,
  searchPlaylists,
  searchFolders,
  isResourceDeleted,
  getResourceIdsFromNodeIds,
  canEditResource,
} from '../../utilities';
import DomainAccountSelector from '../../components/DomainAccountSelector';
import LibraryEmptyState from '../../components/LibraryEmptyState';
import * as libraryTreeActions from '../../components/LibraryTree/actions';
import * as paths from '../../routes/paths';
import * as presentationPageActions from '../PresentationPage/actions';
import * as R from '../../clients/mira/types/Resource';
import * as actions from './actions';
import {
  selectMainSortOptions,
  selectSideSortOptions,
  selectSearchQuery,
  selectLastLoadedDate,
  selectSelectedNodeIds,
} from './selectors';
import useStyles from './LibraryPage.styles';
import LibraryMainActionBar from './LibraryMainActionBar';
import LibrarySideActionBar from './LibrarySideActionBar';
import LibraryThumbnailView from './LibraryThumbnailView';
import LibraryFolderEmptyState from './LibraryFolderEmptyState';

interface LibraryPageProps {}

const LibraryPage: FC<LibraryPageProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const folderMatch = useRouteMatch<{ folderId?: string }>({
    path: paths.libraryFolder.pattern,
  });
  const profileMatch = useRouteMatch<{ profileId?: string }>({
    path: paths.libraryProfile.pattern,
  });

  let pageUrl = paths.library();
  if (folderMatch?.params?.folderId) {
    pageUrl = paths.libraryFolder(folderMatch.params.folderId);
  } else if (profileMatch?.params?.profileId) {
    pageUrl = paths.libraryProfile(profileMatch.params.profileId);
  }

  // Selectors

  const libraryByOwner = useSelector(selectLibraryByOwner);
  const foldersById = useSelector(selectFoldersById);
  const folderStatusById = useSelector(selectFolderStatusById);
  const mainSortOptions = useSelector(selectMainSortOptions);
  const sideSortOptions = useSelector(selectSideSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const lastLoadedDate = useSelector(selectLastLoadedDate);
  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const domain = useSelector(selectDomainForCurrentUser);
  const selectedNodeIds = useSelector(selectSelectedNodeIds);

  // State

  const [isManageMode, setIsManageMode] = useState(false);
  const [updatedFolderName, setUpdatedFolderName] = useState<string | null>(
    null,
  );

  // Refs

  const folderNameRef = useRef<HTMLInputElement | null>(null);

  // Memoizers

  const selectedFolderId = folderMatch?.params?.folderId ?? '';
  const selectedFolder = foldersById[selectedFolderId];

  const selectedProfileId = selectedFolder
    ? selectedFolder.resource.profile.id
    : profileMatch?.params?.profileId ?? currentUser?.id ?? '';

  let selectedProfile: R.ResourceProfile | undefined;

  if (isEnterpriseUser && domain) {
    selectedProfile = domain.r.profiles.find((p) => p.id === selectedProfileId);
  } else if (!isEnterpriseUser && currentUser) {
    // Map full user profile to a resource profile.
    selectedProfile = {
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      thumbnailUrl: currentUser.thumbnailUrl || '',
      domainId: currentUser.domainId,
      domainRole: currentUser.domainRole,
    };
  }

  const libraryFolder = libraryByOwner[selectedProfileId];
  const folder = selectedFolderId ? selectedFolder : libraryFolder;

  const folderItems = useMemo(() => {
    if (!folder) return [];

    return sortFolder(
      searchPresentations(
        folder.presentations.filter((p) => !isResourceDeleted(p)),
        searchQuery,
      ),
      searchPlaylists(
        folder.playlists.filter((pl) => !isResourceDeleted(pl)),
        searchQuery,
      ),
      searchFolders(
        folder.folders.filter((f) => !isResourceDeleted(f)),
        searchQuery,
      ),
      mainSortOptions,
    );
  }, [folder, mainSortOptions, searchQuery]);

  // Callbacks

  const updateFolder = useCallback(
    (updatedFolder: Partial<F.Folder>, onUpdate?: () => void) => {
      if (!selectedFolder) return;
      dispatch(
        folderActions.updateFolder(
          {
            ...selectedFolder,
            ...updatedFolder,
          },
          { onUpdate },
        ),
      );
    },
    [dispatch, selectedFolder],
  );

  const updateFolderName = useCallback(() => {
    if (!selectedFolder) return;
    if (updatedFolderName === null) return;
    if (updatedFolderName === selectedFolder.name) return;
    updateFolder({ name: updatedFolderName }, () => {
      // Clear updated folder name after save.
      setUpdatedFolderName(null);
    });
  }, [updateFolder, updatedFolderName, selectedFolder]);

  const moveNodeIdsToFolder = useCallback(
    (nodeIds: string[], folderId: string | null) => {
      const { presentationIds, playlistIds, folderIds } =
        getResourceIdsFromNodeIds(nodeIds);

      dispatch(
        folderActions.moveAllItemsToFolder(
          {
            presentationIds,
            playlistIds,
            folderIds,
            parentFolderId: folderId,
          },
          {},
        ),
      );

      dispatch(actions.clearSelectedNodeIds());
      dispatch(libraryTreeActions.clearSelectedNodeIds());
    },
    [dispatch],
  );

  const openFolder = useCallback(
    (folderId: string, focusName?: boolean) => {
      history.push(paths.libraryFolder(folderId));

      if (focusName && folderNameRef.current) {
        folderNameRef.current.focus();
        folderNameRef.current.select();
      }
    },
    [history],
  );

  const selectProfile = useCallback(
    (profileId: string) => {
      if (profileId === currentUser?.id) {
        history.push(paths.library());
      } else {
        history.push(paths.libraryProfile(profileId));
      }
    },
    [history, currentUser],
  );

  const backToParentFolder = useCallback(() => {
    if (!selectedFolder) return;
    if (selectedFolder.resource.parentFolderId) {
      openFolder(selectedFolder.resource.parentFolderId);
    } else {
      // Go back to the current user's or selected domain account's top-level library.
      selectProfile(selectedProfileId);
    }
  }, [selectedFolder, openFolder, selectProfile, selectedProfileId]);

  const editPlaylist = useCallback(
    (playlistId: string) => {
      history.push(
        paths.editPlaylist(playlistId, {
          backTo: pageUrl,
          backToLabel: 'Back To Library',
          saveTo: pageUrl,
          folderId: selectedFolderId,
        }),
      );
    },
    [history, pageUrl, selectedFolderId],
  );

  const editPresentation = useCallback(
    (presentationId: string) => {
      dispatch(
        presentationPageActions.clearUnsavedPresentation(presentationId),
      );

      history.push(
        paths.editPresentation(presentationId, {
          backTo: pageUrl,
          backToLabel: 'Back to Library',
          saveTo: pageUrl,
        }),
      );
    },
    [history, dispatch, pageUrl],
  );

  const newPresentation = useCallback(
    (applicationId: string) => {
      dispatch(presentationPageActions.clearUnsavedPresentation(applicationId));

      history.push(
        paths.newPresentation({
          applicationId,
          backTo: pageUrl,
          backToLabel: 'Back to Library',
          saveTo: pageUrl,
        }),
      );
    },
    [dispatch, history, pageUrl],
  );

  const openAppSelector = useCallback(() => {
    if (folder && 'id' in folder) {
      history.push(
        paths.applications({
          backTo: pageUrl,
          folderId: folder.id,
        }),
      );
    } else {
      history.push(paths.applications({ backTo: pageUrl }));
    }
  }, [history, folder, pageUrl]);

  const setSelectedNodeIds = useCallback(
    (nodeIds: string[]) => {
      dispatch(actions.setSelectedNodeIds(nodeIds));
    },
    [dispatch],
  );

  const toggleNodeId = useCallback(
    (nodeId: string) => {
      setSelectedNodeIds(xor(selectedNodeIds, [nodeId]));
    },
    [setSelectedNodeIds, selectedNodeIds],
  );

  // Side-effects

  // Fetch library on page load.
  const initialFolderIdRef = useRef<string>(selectedFolderId);
  useEffect(() => {
    dispatch(actions.loadLibraryPage({ folderId: initialFolderIdRef.current }));
  }, [dispatch]);

  // Fetch selected folder.
  useEffect(() => {
    if (!initialFolderIdRef.current) {
      // If initialFolderIdRef.current is set then the will be folder is fetched
      // via loadLibraryPage so that it is used to show the loading spinner.
      if (selectedFolderId) {
        dispatch(folderActions.fetchFolder(selectedFolderId, {}));
      }
    } else {
      // Reset the initialFolderIdRef so that folder navigation after page load
      // fetches the selected folder.
      initialFolderIdRef.current = '';
    }
  }, [dispatch, selectedFolderId]);

  // Clear updated folder name when switching folders.
  useEffect(() => {
    setUpdatedFolderName(null);
  }, [dispatch, selectedFolderId, setUpdatedFolderName]);

  // Poll presentations with file uploads when folder items changes.
  useEffect(() => {
    const presentationIds: string[] = [];

    folderItems.forEach((item) => {
      if (item.presentation) {
        presentationIds.push(item.presentation.id);
      }
    });

    dispatch(
      presentationActions.pollPresentationsWithFileUploads(presentationIds),
    );
  }, [dispatch, folderItems]);

  // Stop polling when unmounting.
  // @ts-ignore
  useEffect(() => {
    return () => dispatch(presentationActions.stopPollingPresentations());
  }, [dispatch]);

  // Render

  const totalFolderItems = folderItems.length;
  const hasNoLibraryItems =
    !totalFolderItems && !searchQuery && !selectedFolderId;
  const hasNoFolderItems =
    !totalFolderItems && !searchQuery && !!selectedFolderId;

  const isLoadingLibrary =
    (hasNoLibraryItems && !lastLoadedDate) || !currentUser;

  if (isLoadingLibrary) {
    return (
      <Page title="Library">
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  const libraryTitle =
    selectedProfileId === currentUser?.id
      ? 'My Library'
      : `${selectedProfile?.name} Library`;

  const isLoadingFolder =
    selectedFolderId &&
    folderStatusById[selectedFolderId] === 'fetching' &&
    hasNoFolderItems;

  const isFolderEditable =
    selectedFolder &&
    currentUser &&
    canEditResource(currentUser, selectedFolder.resource);

  const renderLibraryEmptyState =
    hasNoLibraryItems && selectedProfileId === currentUser?.id;

  const renderFolderEmptyState =
    !isLoadingFolder &&
    hasNoFolderItems &&
    selectedProfileId === currentUser?.id;

  const renderThumbnailView = () => {
    if (isLoadingLibrary || isLoadingFolder) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    if (renderFolderEmptyState && selectedProfile) {
      return (
        <LibraryFolderEmptyState
          folder={folder}
          selectedProfile={selectedProfile}
          onMove={moveNodeIdsToFolder}
        />
      );
    }

    if (renderLibraryEmptyState) {
      return <LibraryEmptyState onApplicationClick={newPresentation} />;
    }

    if (selectedProfile && folder) {
      return (
        <LibraryThumbnailView
          selectedNodeIds={selectedNodeIds}
          folder={folder}
          folderItems={folderItems}
          selectedProfile={selectedProfile}
          isManageMode={isManageMode}
          onMove={moveNodeIdsToFolder}
          onOpenFolder={openFolder}
          onOpenPlaylist={editPlaylist}
          onOpenPresentation={editPresentation}
          onSelectNodeIds={setSelectedNodeIds}
          onToggleNodeId={toggleNodeId}
        />
      );
    }

    return <Spacer />;
  };

  return (
    <Page title="Library">
      {isEnterpriseUser && (
        <Paper color="light" className={classes.domainLibraries}>
          <DomainAccountSelector
            className={classes.domainAccountSelector}
            selectedProfileId={selectedProfileId}
            onSelect={selectProfile}
          />
        </Paper>
      )}

      <Paper color="light" className={classes.library}>
        <div className={classes.libraryMain}>
          <div className={classes.header}>
            <Hidden xsDown>
              {!selectedFolder && (
                <>
                  <Title>{libraryTitle}</Title>
                  {isEnterpriseUser && (
                    <Text xsmall muted>
                      {domain?.name}
                    </Text>
                  )}
                </>
              )}

              {selectedFolder && (
                <>
                  <Title>
                    <Row halfMargin center className={classes.folderNameRow}>
                      <ArrowBackIcon
                        fontSize="large"
                        onClick={backToParentFolder}
                        style={{ cursor: 'pointer' }}
                      />
                      <BreadcrumbDivider />
                      <div>
                        <Text
                          editable={isFolderEditable}
                          ref={folderNameRef}
                          value={updatedFolderName ?? selectedFolder.name ?? ''}
                          maxWidth={480}
                          onChange={setUpdatedFolderName}
                          onBlur={updateFolderName}
                        />
                      </div>
                    </Row>
                  </Title>
                  {isEnterpriseUser && (
                    <Text xsmall muted className={classes.folderNameHelperText}>
                      {libraryTitle}
                    </Text>
                  )}
                </>
              )}
            </Hidden>

            <LibraryMainActionBar
              folder={folder}
              selectedProfile={selectedProfile}
              sortDisabled={hasNoLibraryItems || hasNoFolderItems}
              manageDisabled={hasNoLibraryItems || hasNoFolderItems}
              onManageMode={setIsManageMode}
              onMove={moveNodeIdsToFolder}
              onOpenFolder={openFolder}
              onCreate={openAppSelector}
            />
          </div>

          {renderThumbnailView()}
        </div>

        <Hidden mdDown>
          <div className={classes.librarySide}>
            <div className={classes.header}>
              <Title>All Folders</Title>
              {isEnterpriseUser && (
                <Text xsmall muted>
                  {libraryTitle}
                </Text>
              )}
              <LibrarySideActionBar sortDisabled={hasNoLibraryItems} />
            </div>

            <Scrollable>
              <div className={classes.libraryTree}>
                {renderLibraryEmptyState && (
                  <div className={classes.libraryTreeSkeleton} />
                )}

                {selectedProfile && !renderLibraryEmptyState && (
                  <LibraryTree
                    selectMode="multiple"
                    selectedProfile={selectedProfile}
                    sortOptions={sideSortOptions}
                    onOpenPlaylist={editPlaylist}
                    onOpenPresentation={editPresentation}
                  />
                )}
              </div>
            </Scrollable>
          </div>
        </Hidden>
      </Paper>

      <LibraryDragLayer />
    </Page>
  );
};

export default LibraryPage;
