import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    aiSettingList: {
      listStyleType: 'none',
      padding: 0,
    },
    aiSetting: {
      marginBottom: '10px',
    },
    aiVideoStream: {
      border: `1px solid ${theme.palette.primary.main}`,
      width: '100%',
    },

    licenseKey: {
      marginBottom: theme.spacing(1),
    },

    title: {
      marginBottom: theme.spacing(0.5),
    },

    type: {
      margin: 0,
    },

    section: {
      marginBottom: theme.spacing(5),

      '&:last-child': {
        marginBottom: 0,
      },
    },
  });
});
