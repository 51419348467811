import {
  FC,
  useCallback,
  MouseEventHandler,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  PlaylistItem as Item,
  Playlist,
  Profile,
} from '@raydiant/api-client-js';
import { isCycle } from './usePlaylistPageState/utilities';
import { canEditResource, canReadResource } from '../../utilities/permissions';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ItemIDPath, PlaylistIDPath } from './playlistPageTypes';
import PlaylistItemPlaylist from './PlaylistItemPlaylist';
import PlaylistItemPresentation from './PlaylistItemPresentation';
import usePlaylistPageContext from './usePlaylistPageContext';
import createPlaylistItemDragItem, {
  PlaylistItemDragItem,
} from './createPlaylistItemDragItem';

interface PlaylistItemProps {
  item: Item;
  itemIdPath: ItemIDPath;
  parentPlaylist: Playlist;
  playlistIdPath: PlaylistIDPath;
}

const canDrop = (
  currentUser: Profile,
  item: Item,
  parentPlaylist: Playlist,
  dropPosition: string,
) => {
  if (currentUser) {
    if (dropPosition === 'center') {
      const itemResource =
        item.presentation?.resource ?? item.playlist?.resource;

      if (itemResource) {
        return canEditResource(currentUser, itemResource);
      }
    } else {
      return canEditResource(currentUser, parentPlaylist.resource);
    }
  }
  return false;
};

const canDrag = (currentUser: Profile, item: Item) => {
  if (currentUser) {
    const itemResource = item.presentation?.resource ?? item.playlist?.resource;

    if (itemResource) {
      return canEditResource(currentUser, itemResource);
    }
  }
  return false;
};

const PlaylistItem: FC<PlaylistItemProps> = ({
  item,
  itemIdPath,
  playlistIdPath,
  parentPlaylist,
}) => {
  const {
    toggleExpanded,
    isExpanded,
    toggleSelected,
    setInitialSelected,
    setSelectedEnd,
    isSelected,
    getSelectedItems,
    moveSelectedItems,
    setPreviewItem,
    openMoreActions,
    isMoreActionsOpen,
    isNameEditable,
    getPlaylist,
    getPresentation,
    editPresentation,
    openModal,
  } = usePlaylistPageContext();

  // Queries

  const { data: currentUser } = useCurrentUser();

  // State

  const [dropPosition, setDropPosition] = useState<
    'top' | 'bottom' | 'center' | null
  >(null);

  const itemRef = useRef<HTMLElement | null>(null);

  // DnD

  const [{ isDragging }, connectDragSource, dragPreview] = useDrag<
    PlaylistItemDragItem,
    void,
    { isDragging: boolean }
  >({
    item: createPlaylistItemDragItem(),

    begin: () => {
      if (!itemRef.current) {
        return createPlaylistItemDragItem();
      }

      // If current item isn't selected, assume user is starting a new selection
      // and de-select any existing items.
      const shouldResetSelection = !isSelected(itemIdPath);
      if (shouldResetSelection) {
        setInitialSelected(itemIdPath);
      }

      // Selected state isn't updated until after this callback runs so we need to
      // empty the drag stack if we are reseting the selection.
      const stack = shouldResetSelection
        ? [item]
        : [item, ...getSelectedItems(itemIdPath)];

      const { x, y, width } = itemRef.current.getBoundingClientRect();

      return createPlaylistItemDragItem(stack, x, y, width);
    },

    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, connectDropSource] = useDrop<
    PlaylistItemDragItem,
    void,
    { isOver: boolean }
  >({
    accept: 'PlaylistItem',

    drop: () => {
      if (!isOver || !dropPosition) return;
      moveSelectedItems(itemIdPath, dropPosition);
    },

    hover: (_, monitor) => {
      if (!itemRef.current) return;
      // if (isCycle(item.playlistId))

      const itemRect = itemRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const topEnd =
        itemRect.y + itemRect.height * (item.playlistId ? 0.33 : 0.5);
      const bottomStart =
        itemRect.y + itemRect.height * (item.playlistId ? 0.66 : 0.5);

      // Calculate the drop position based on the hover coordinates. We also need to check
      // if the user is allowed to drop on the item or parent playlist here to avoid rendering
      // the drop states.
      if (
        clientOffset.y < topEnd &&
        currentUser &&
        canDrop(currentUser, item, parentPlaylist, 'top')
      ) {
        setDropPosition('top');
      } else if (
        clientOffset.y < bottomStart &&
        currentUser &&
        canDrop(currentUser, item, parentPlaylist, 'center')
      ) {
        setDropPosition('center');
      } else if (
        currentUser &&
        canDrop(currentUser, item, parentPlaylist, 'bottom')
      ) {
        setDropPosition('bottom');
      } else {
        setDropPosition(null);
      }
    },

    collect: (monitor) => {
      return {
        isOver: monitor.isOver({ shallow: true }),
      };
    },

    canDrop: () => {
      return !!dropPosition;
    },
  });

  // Callbacks

  const toggleExpandedItem = useCallback<MouseEventHandler>(
    (event) => {
      if (!item.playlistId) return;
      // Prevent main onClick handler from running
      event.stopPropagation();
      // Show / hide playlist items
      toggleExpanded(itemIdPath, item.playlistId);
    },
    [item.playlistId, itemIdPath, toggleExpanded],
  );

  const showMoreActions = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      // Prevent main onClick handler from running
      event.stopPropagation();
      if (!itemRef.current) return;
      openMoreActions(item, itemIdPath, itemRef.current);
    },
    [item, itemIdPath, openMoreActions],
  );

  const showSchedule = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      // Prevent main onClick handler from running
      event.stopPropagation();
      if (!item || !itemIdPath) return;
      openModal(item, itemIdPath, 'schedule');
    },
    [item, itemIdPath, openModal],
  );

  const showRules = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      // Prevent main onClick handler from running
      event.stopPropagation();
      if (!item || !itemIdPath) return;
      openModal(item, itemIdPath, 'rules');
    },
    [item, itemIdPath, openModal],
  );

  const showTags = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      // Prevent main onClick handler from running
      event.stopPropagation();
      if (!item || !itemIdPath) return;
      openModal(item, itemIdPath, 'tags');
    },
    [item, itemIdPath, openModal],
  );

  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      if (event.shiftKey) {
        // Shift-click, select items between initial click.
        setSelectedEnd(itemIdPath);
      } else if (event.metaKey) {
        // Cmd-click, toggle item in selected list.
        toggleSelected(itemIdPath);
      } else if (item.presentationId && isSelected(itemIdPath)) {
        // If second click on presentation, navigate to presentation builder.
        editPresentation(item.presentationId);
      } else {
        // Reset selected list and toggle selected item.
        setInitialSelected(itemIdPath);
        // Set the preview item.
        setPreviewItem(item);
      }
    },
    [
      item,
      itemIdPath,
      setSelectedEnd,
      toggleSelected,
      setInitialSelected,
      setPreviewItem,
      editPresentation,
      isSelected,
    ],
  );

  // Disable the HTML5 drag preview image becuase we are rendering our
  // own custom drag layer.
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  useLayoutEffect(() => {
    if (itemRef.current && currentUser) {
      if (canDrag(currentUser, item)) {
        connectDragSource(itemRef.current);
      }
      connectDropSource(itemRef.current);
    }
  }, [connectDragSource, connectDropSource, currentUser, item]);

  // Render

  const indent = Math.max(itemIdPath.length - 1, 0);
  const selected = isDragging || isSelected(itemIdPath);

  // Use playlist from page state to get the latest changes.
  const itemPlaylist = item.playlistId
    ? getPlaylist(item.playlistId) ?? item.playlist
    : undefined;

  // Use presentation from page state to get the latest changes.
  const itemPresentation = item.presentationId
    ? getPresentation(item.presentationId) ?? item.presentation
    : undefined;

  if (currentUser && itemPlaylist) {
    // TODO: There is a bug where itemPlaylist doesn't reflect the updated playlist unless
    // the playlist has expanded.
    const hasSchedule = !!itemPlaylist.startDatetime;
    const hasRule = !!itemPlaylist.rule;
    const hasTags = itemPlaylist.resource.r.tags.length > 0;
    const cyclic = isCycle(itemPlaylist.id, playlistIdPath);

    return (
      <PlaylistItemPlaylist
        ref={itemRef}
        playlist={itemPlaylist}
        indent={indent}
        expanded={isExpanded(itemIdPath)}
        cyclic={cyclic}
        selected={selected}
        disabled={!canEditResource(currentUser, itemPlaylist.resource)}
        dropPosition={isOver && dropPosition ? dropPosition : undefined}
        actionsOpen={isMoreActionsOpen(itemIdPath)}
        editable={isNameEditable(itemIdPath)}
        itemIdPath={itemIdPath}
        playlistIdPath={playlistIdPath}
        onClick={handleClick}
        onScheduleClick={hasSchedule ? showSchedule : undefined}
        onRulesClick={hasRule ? showRules : undefined}
        onTagsClick={hasTags ? showTags : undefined}
        onToggleExpandClick={
          canReadResource(currentUser, itemPlaylist.resource)
            ? toggleExpandedItem
            : undefined
        }
        onMoreClick={showMoreActions}
      />
    );
  }

  if (currentUser && itemPresentation) {
    return (
      <PlaylistItemPresentation
        ref={itemRef}
        presentation={itemPresentation}
        indent={indent}
        selected={selected}
        disabled={!canEditResource(currentUser, itemPresentation.resource)}
        dropPosition={
          isOver && dropPosition && dropPosition !== 'center'
            ? dropPosition
            : undefined
        }
        actionsOpen={isMoreActionsOpen(itemIdPath)}
        onClick={handleClick}
        onMoreClick={showMoreActions}
        onTagsClick={showTags}
      />
    );
  }

  return null;
};

export default PlaylistItem;
