import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import { FC } from 'react';

type UpdatePaymentMethodErrorDialogProps = {
  errorCode: string;
  handleConfirm: () => void;
};

const UpdatePaymentMethodErrorDialog: FC<
  UpdatePaymentMethodErrorDialogProps
> = ({ errorCode, handleConfirm }) => {
  return (
    <>
      <DialogTitle>Something Went Wrong</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To update your payment method please reach out to{' '}
          <a href="mailto:support@raydiant.com">support@raydiant.com</a>{' '}
          {errorCode.length > 0 && (
            <span>
              {' '}
              and include error code <strong>{errorCode}</strong>
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default UpdatePaymentMethodErrorDialog;
