import { FC, useState } from 'react';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import {
  ruleSourceOptionGroups,
  ruleSources,
  RuleSourceID,
  RuleSourceOption,
  RuleSourceOptionGroup,
} from './ruleTokenInputData';
import { MenuItem } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import NestedMenuItem from '../NestedMenuItem';
import useStyles from './RuleSelect.styles';
export interface RuleSourceSelectProps {
  disabled?: boolean;
  label: string;
  onChange: (value: RuleSourceID | null) => void;
  ruleSource?: RuleSourceID | null;
  value: RuleSourceID | null;
  isSource1?: boolean;
  isSource2?: boolean;
}

const menuValues = (menuItem: RuleSourceID) => menuItem.split('.');

const rootMenu = (menuItem: RuleSourceID) => menuValues(menuItem)[0];

const locationTag = (menuItem: RuleSourceID) => menuValues(menuItem)[2];

const schedulingTag = (menuItem: RuleSourceID) => menuValues(menuItem)[1];

const schedulingSource = (menuItem: RuleSourceID) => menuValues(menuItem)[2];

const RuleSourceSelect: FC<RuleSourceSelectProps> = ({
  disabled = false,
  label,
  onChange,
  ruleSource,
  value,
  isSource1,
  isSource2,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => setOpen(open ? false : true);

  const filterSchedulingMenuItems = (group: RuleSourceOptionGroup) => {
    if (!isSource2) {
      return true;
    }

    return isSource2 && group.name !== 'Schedule';
  };

  const filterTimeAndDateMenuItems = (group: RuleSourceOptionGroup) => {
    if (!isSource1) {
      return true;
    }

    return isSource1 && group.name !== 'Time & Date';
  };

  const isMenuItemDisabled = (menuItem: RuleSourceID): boolean => {
    if (!ruleSource) {
      return false;
    }

    if (
      schedulingSource(ruleSource) === 'start_time' ||
      schedulingSource(ruleSource) === 'end_time'
    ) {
      return schedulingTag(menuItem) !== 'curr_time';
    }

    if (
      schedulingSource(ruleSource) === 'start_date' ||
      schedulingSource(ruleSource) === 'end_date'
    ) {
      return schedulingTag(menuItem) !== 'curr_date';
    }

    if (schedulingSource(ruleSource) === 'days_of_week') {
      return schedulingTag(menuItem) !== 'curr_day';
    }

    return (
      rootMenu(ruleSource) === rootMenu(menuItem) ||
      locationTag(ruleSource) !== locationTag(menuItem)
    );
  };

  const renderMenuItem = (
    option: RuleSourceOption | RuleSourceOptionGroup,
    index: number,
  ) => {
    if ('groups' in option) {
      return (
        <NestedMenuItem
          disabled={option.groups?.every((option: RuleSourceOption) =>
            option.options?.every((sourceId) => isMenuItemDisabled(sourceId)),
          )}
          className={classes.menuItem}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.groups
            ?.filter(filterSchedulingMenuItems)
            .map(renderMenuItem)}
        </NestedMenuItem>
      );
    } else {
      return (
        <NestedMenuItem
          disabled={option.options?.every((sourceId) =>
            isMenuItemDisabled(sourceId),
          )}
          className={classes.menuItem}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.options?.map((sourceId) => {
            return (
              <MenuItem
                className={classes.menuItem}
                disabled={isMenuItemDisabled(sourceId)}
                key={sourceId}
                value={sourceId}
                selected={sourceId === value}
                onClick={() => {
                  onChange(sourceId);
                  toggleMenu();
                }}
              >
                {ruleSources[sourceId].label}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
      );
    }
  };

  const renderDesktopMenu = () => (
    <>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <Select
        classes={{
          select: classes.select,
        }}
        disabled={disabled}
        native={false}
        open={open}
        onClose={toggleMenu}
        onChange={() => toggleMenu()}
        onOpen={toggleMenu}
        value={value ?? ''}
        renderValue={() => (value ? ruleSources[value]?.label : '')}
      >
        {ruleSourceOptionGroups
          .filter(filterTimeAndDateMenuItems)
          .map(renderMenuItem)}
      </Select>
    </>
  );

  const renderMobileMenuItem = (
    option: RuleSourceOption | RuleSourceOptionGroup,
    index: number,
  ): any => {
    if ('groups' in option) {
      return option.groups?.map(renderMobileMenuItem);
    } else {
      return option.options?.map((sourceId) => (
        <option
          key={index}
          disabled={isMenuItemDisabled(sourceId)}
          value={sourceId}
        >
          {ruleSources[sourceId].longLabel}
        </option>
      ));
    }
  };

  const renderMobileMenu = () => (
    <>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      <Select
        disabled={disabled}
        native={true}
        onChange={(event) => onChange(event as RuleSourceID)}
        value={value ?? ''}
      >
        <option value=""></option>
        {ruleSourceOptionGroups
          .filter(filterTimeAndDateMenuItems)
          .map(renderMobileMenuItem)}
      </Select>
    </>
  );

  return (
    <>
      <Hidden xsDown>{renderDesktopMenu()}</Hidden>
      <Hidden smUp>{renderMobileMenu()}</Hidden>
    </>
  );
};

export default RuleSourceSelect;
