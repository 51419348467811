import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CreateNewFolderSharpIcon from '@material-ui/icons/CreateNewFolderSharp';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ManageMultipleIcon from 'raydiant-elements/icons/ManageMultiple';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteResourcesConfirmation from '../../components/DeleteResourcesConfirmation';
import logger from '../../logger';
import miraClient from '../../clients/miraClient';
import * as F from '../../clients/mira/types/Folder';
import * as D from '../../clients/mira/types/Device';
import * as R from '../../clients/mira/types/Resource';
import { selectUserProfile } from '../../selectors/user';
import * as presentationActions from '../../actions/presentations';
import * as playlistActions from '../../actions/playlists';
import * as folderActions from '../../actions/folders';
import AddIcon from '../../components/AddIcon';
import {
  getResourceIdsFromNodeIds,
  parseNodeId,
  SortFolderOptions,
  canMoveFolderItems,
  canCreateFolderItem,
} from '../../utilities';
import {
  selectMainSortOptions,
  selectSelectedNodeIds,
  selectSearchQuery,
} from './selectors';
import * as actions from './actions';

interface LibraryMainActionBarProps {
  folder?: F.Folder | F.VirtualFolder;
  selectedProfile?: R.ResourceProfile;
  sortDisabled?: boolean;
  manageDisabled?: boolean;
  onManageMode: (isManageMode: boolean) => void;
  onMove: (nodeIds: string[], folderId: string | null) => void;
  onOpenFolder: (folderId: string, focusName: boolean) => void;
  onCreate: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
      },
    },

    alert: {
      color: theme.palette.warning.main,
      backgroundColor: '#000',
      borderRadius: 100,
    },
  }),
);

const LibraryMainActionBar: FC<LibraryMainActionBarProps> = ({
  folder,
  selectedProfile,
  sortDisabled,
  manageDisabled,
  onManageMode,
  onMove,
  onOpenFolder,
  onCreate,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentFolderId = folder && 'id' in folder ? folder.id : null;
  const parentFolderId =
    folder && 'id' in folder ? folder.resource.parentFolderId : null;

  // Refs

  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);

  // Selectors

  const selectedNodeIds = useSelector(selectSelectedNodeIds);
  const sortOptions = useSelector(selectMainSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const currentUser = useSelector(selectUserProfile);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isManageMode, setIsManageMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [affectedDevices, setAffectedDevices] = useState<D.AffectedDevice[]>(
    [],
  );
  const [affectedDevicesStatus, setAffectedDevicesStatus] = useState<
    '' | 'pending' | 'success' | 'failed'
  >('');
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortFolderOptions['property']) => {
      let direction: SortFolderOptions['direction'] =
        property === 'updatedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setMainSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  const deleteOrPromptIfAffectedDevices = useCallback(async () => {
    setAffectedDevices([]);
    setAffectedDevicesStatus('pending');

    try {
      const affectedDevices = await miraClient.getAffectedDevices(
        getResourceIdsFromNodeIds(selectedNodeIds),
      );

      setAffectedDevices(affectedDevices);
      setAffectedDevicesStatus('success');
      setIsDeletePromptOpen(true);
    } catch (err: any) {
      logger.error(err);
      setAffectedDevicesStatus('failed');
    }
  }, [
    selectedNodeIds,
    setAffectedDevices,
    setAffectedDevicesStatus,
    setIsDeletePromptOpen,
  ]);

  const deleteSelected = useCallback(() => {
    setIsDeletePromptOpen(false);

    const presentationIds: string[] = [];
    const playlistIds: string[] = [];
    const folderIds: string[] = [];

    selectedNodeIds.forEach((nodeId) => {
      const { type, id } = parseNodeId(nodeId);
      if (type === 'presentation') presentationIds.push(id);
      if (type === 'playlist') playlistIds.push(id);
      if (type === 'folder') folderIds.push(id);
    });

    dispatch(presentationActions.deleteAllPresentations(presentationIds, {}));
    dispatch(playlistActions.deleteAllPlaylists(playlistIds, {}));
    dispatch(folderActions.deleteAllFolders(folderIds, {}));
    dispatch(actions.clearSelectedNodeIds());
  }, [dispatch, selectedNodeIds]);

  const moveUp = useCallback(() => {
    onMove(selectedNodeIds, parentFolderId);
  }, [selectedNodeIds, parentFolderId, onMove]);

  const newFolder = useCallback(() => {
    const onCreate = (folder: F.Folder) => {
      // Open new folder.
      onOpenFolder(folder.id, true);

      // Move selected items to new folder.
      onMove(selectedNodeIds, folder.id);

      // Move new folder to parent folder.
      dispatch(
        folderActions.moveAllItemsToFolder(
          {
            folderIds: [folder.id],
            parentFolderId: currentFolderId,
          },
          {},
        ),
      );
    };

    dispatch(
      folderActions.createFolder(
        {
          name: 'New Folder',
          description: null,
          type: 'library',
        },
        { onCreate },
      ),
    );
  }, [dispatch, currentFolderId, selectedNodeIds, onMove, onOpenFolder]);

  const toggleManageMultiple = useCallback(() => {
    setIsManageMode(!isManageMode);
    setIsSortMode(false);
    if (isManageMode) {
      dispatch(actions.setSelectedNodeIds([]));
    }
  }, [isManageMode, dispatch]);

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (isSearchMode) return;
    dispatch(actions.setSearchQuery(''));
  }, [isSearchMode, dispatch]);

  // Call onManageMode when isManageMode changes.
  useEffect(() => {
    onManageMode(isManageMode);
  }, [onManageMode, isManageMode]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'type') {
    sortLabel = `${sortLabel}: Type`;
  } else if (sortOptions.property === 'updatedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByType = sortOptions.property === 'type';
  const isSortByDate = sortOptions.property === 'updatedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={
          isSortByName && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Name"
        selected={isSortByName}
        onClick={() => toggleSort('name')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByType && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Type"
        selected={isSortByType}
        onClick={() => toggleSort('type')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByDate && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Date"
        selected={isSortByDate}
        onClick={() => toggleSort('updatedAt')}
      />
    </>
  );

  const sortAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label={sortLabel}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const shouldDisableNewFolder =
    (isManageMode && selectedNodeIds.length === 0) ||
    (currentUser &&
      selectedProfile &&
      !canCreateFolderItem(currentUser, selectedProfile));

  const newFolderAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<CreateNewFolderSharpIcon />}
          label="New Folder"
          disabled={shouldDisableNewFolder}
          onClick={newFolder}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={<CreateNewFolderSharpIcon />}
          disabled={shouldDisableNewFolder}
          onClick={newFolder}
        />
      </Hidden>
    </>
  );

  const shouldDisableManage = manageDisabled;

  const manageMultipleAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={isManageMode ? <CloseIcon /> : <ManageMultipleIcon />}
          label={isManageMode ? 'Done' : 'Manage Multiple'}
          selected={isManageMode}
          disabled={shouldDisableManage}
          onClick={toggleManageMultiple}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={isManageMode ? <CloseIcon /> : <ManageMultipleIcon />}
          selected={isManageMode}
          disabled={shouldDisableManage}
          onClick={toggleManageMultiple}
        />
      </Hidden>
    </>
  );

  const createDisabled =
    currentUser &&
    selectedProfile &&
    !canCreateFolderItem(currentUser, selectedProfile);

  const createAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<AddIcon />}
          disabled={createDisabled}
          label="Create"
          onClick={onCreate}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={<AddIcon />}
          disabled={createDisabled}
          onClick={onCreate}
        />
      </Hidden>
    </>
  );

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      value={searchQuery}
      onChange={(value) => setSearchQuery(value)}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
    />
  );

  const disableDelete =
    selectedNodeIds.length === 0 || affectedDevicesStatus === 'pending';

  const deleteAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          ref={deleteButtonRef}
          icon={<DeleteIcon />}
          label={affectedDevicesStatus === 'pending' ? 'Deleting...' : 'Delete'}
          disabled={disableDelete}
          onClick={deleteOrPromptIfAffectedDevices}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          ref={deleteButtonRef}
          icon={<DeleteIcon />}
          disabled={disableDelete}
          onClick={deleteOrPromptIfAffectedDevices}
        />
      </Hidden>
    </>
  );

  const isMoveable =
    currentUser &&
    selectedProfile &&
    canMoveFolderItems(currentUser, selectedProfile);

  const disableMove = !isMoveable || selectedNodeIds.length === 0;
  const moveUpAction = folder && 'id' in folder && (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<ArrowUpwardIcon />}
          label="Move Up"
          disabled={disableMove}
          onClick={moveUp}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={<ArrowUpwardIcon />}
          disabled={disableMove}
          onClick={moveUp}
        />
      </Hidden>
    </>
  );

  return (
    <ActionBar className={classes.root}>
      <Hidden smDown>
        {sortAction}
        {newFolderAction}
        {isManageMode && moveUpAction}
        {isManageMode && deleteAction}
        {manageMultipleAction}
        {createAction}
        <Spacer />
        {searchAction}
      </Hidden>

      <Hidden mdUp>
        {sortAction}
        {!isSearchMode && newFolderAction}
        {!isSearchMode && isManageMode && moveUpAction}
        {!isSearchMode && isManageMode && deleteAction}
        {!isSearchMode && manageMultipleAction}
        {!isSearchMode && createAction}
        {searchAction}
      </Hidden>

      <DeleteResourcesConfirmation
        anchorEl={deleteButtonRef.current}
        open={isDeletePromptOpen}
        affectedDevices={affectedDevices}
        onClose={() => setIsDeletePromptOpen(false)}
        onDelete={deleteSelected}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </ActionBar>
  );
};

export default LibraryMainActionBar;
