import { FC } from 'react';
import ActionBar from 'raydiant-elements/core/ActionBar';
import Paper from 'raydiant-elements/core/Paper';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import * as A from '../../clients/mira/types/Application';
import * as U from '../../clients/mira/types/User';
import Page from '../../components/Page';
import Grid from '../../components/Grid';
import GridItem from '../../components/Grid/GridItem';
import { ThemesById } from '../../selectors/v2/themes';
import * as addContentPageActions from '../AddContentPage/actions';
import * as applicationsPageActions from '../ApplicationsPage/actions';
import Heading from 'raydiant-elements/core/Heading';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MarketplaceCard from '../../components/MarketplaceCard/MarketplaceCard';
import useStyles from './MarketplacePage.styles';

import useMarketPlaceApplications from '../../hooks/useMarketPlaceApplications';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import { Application } from '@raydiant/api-client-js';
import Center from 'raydiant-elements/layout/Center';
import MarketplaceLayout from '../../components/MarketplaceLayout';
export interface MarketplacePageProps {
  isLoading: boolean;
  applications: A.Application[];
  premiumApplications: A.Application[];
  backTo: string;
  selectionId?: string;
  folderId?: string;
  isModal?: boolean;
  currentUser?: U.Profile;
  themesById: ThemesById;
  createPlaylistTo: string;
  loadApplicationsPage: typeof applicationsPageActions.loadApplicationsPage;
  selectItem: typeof addContentPageActions.selectItem;
}

const userHasPremiumApp = (
  premiumApp: Application,
  applications: Application[],
) => applications.map((application) => application.id).includes(premiumApp.id);

const MarketplacePage: FC<MarketplacePageProps> = () => {
  const classes = useStyles();
  const { data, isLoading } = useMarketPlaceApplications();

  const renderPremiumContentApps = () => {
    const contentApps = data?.filter(
      (premiumApp) => premiumApp.applicationCategory?.name === 'content',
    );

    return (
      <>
        <ActionBar>
          <Heading size={2} weight={600}>
            Premium Content Apps
          </Heading>
        </ActionBar>
        <Grid paddingTop={false}>
          {contentApps?.map((app) => {
            return (
              <GridItem key={app.id}>
                <div>
                  {userHasPremiumApp(app, data as Application[]) && (
                    <div className={classes.checkmark}>
                      <CheckCircleIcon
                        style={{
                          backgroundColor: '#ffffff',
                          borderRadius: '1.5rem',
                        }}
                      />
                    </div>
                  )}
                  <MarketplaceCard
                    application={app}
                    onClick={() =>
                      app.websiteUrl && window.open(app.websiteUrl)
                    }
                  />
                </div>
              </GridItem>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderPremiumMenuApps = () => {
    const menuApps = data?.filter(
      (premiumApp) => premiumApp.applicationCategory?.name === 'menu',
    );

    return (
      <>
        <ActionBar>
          <Heading size={2} weight={600}>
            Premium Menu Apps
          </Heading>
        </ActionBar>
        <Grid paddingTop={false}>
          {menuApps?.map((app) => {
            return (
              <GridItem key={app.id}>
                {userHasPremiumApp(app, data as Application[]) && (
                  <div className={classes.checkmark}>
                    <CheckCircleIcon
                      style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '1.5rem',
                      }}
                    />
                  </div>
                )}
                <MarketplaceCard
                  application={app}
                  onClick={() => app.websiteUrl && window.open(app.websiteUrl)}
                />
              </GridItem>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderPremiumCommunicationApps = () => {
    const communicationApps = data?.filter(
      (premiumApp) => premiumApp.applicationCategory?.name === 'communication',
    );

    return (
      <>
        <ActionBar>
          <Heading size={2} weight={600}>
            Premium Communication Apps
          </Heading>
        </ActionBar>
        <Grid paddingTop={false}>
          {communicationApps?.map((app) => {
            return (
              <GridItem key={app.id}>
                {userHasPremiumApp(app, data as Application[]) && (
                  <div className={classes.checkmark}>
                    <CheckCircleIcon
                      style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '1.5rem',
                      }}
                    />
                  </div>
                )}
                <MarketplaceCard
                  application={app}
                  onClick={() => app.websiteUrl && window.open(app.websiteUrl)}
                />
              </GridItem>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderMarketplaceApps = () => {
    if (isLoading) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    return (
      <>
        {renderPremiumContentApps()}
        {renderPremiumMenuApps()}
        {renderPremiumCommunicationApps()}
      </>
    );
  };

  return (
    <Page>
      <Paper color="light" className={classes.paper}>
        <Scrollable>
          <Grid>
            <MarketplaceLayout />
            {renderMarketplaceApps()}
          </Grid>
        </Scrollable>
      </Paper>
    </Page>
  );
};

export default MarketplacePage;
