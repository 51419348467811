import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Paper from 'raydiant-elements/core/Paper';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Heading from 'raydiant-elements/core/Heading';
import Center from 'raydiant-elements/layout/Center';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import PlaylistCard from 'raydiant-elements/playlist/PlaylistCard';
import { FC, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from '../../clients/mira/types/Resource';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import * as applicationActions from '../../actions/applications';
import {
  isResourceDeleted,
  createDefaultPlaylist,
  isNotNullOrUndefined,
} from '../../utilities';
import { selectLibraryFolder } from '../../selectors/v2/folders';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../selectors/user';
import { selectApplications } from '../../selectors/applications';
import LibraryTree from '../../components/LibraryTree';
import DomainAccountSelector from '../../components/DomainAccountSelector';
import ApplicationCard from '../../components/ApplicationCard';
import config from '../../config';
import PlaylistSelectorActionBar from './PlaylistSelectorActionBar';
import { selectSortOptions, selectSearchQuery } from './selectors';

interface PlaylistSelectorProps {
  selectedProfileId: string;
  onSelectProfile: (profileId: string) => void;
  onSelectPlaylist: (playlistId: string) => void;
  onNewPlaylist: () => void;
  onNewPresentation: (applicationId: string) => void;
}

const defaultPlaylist = createDefaultPlaylist({ name: 'Create a Playlist' });

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    headerContainer: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    header: {
      margin: theme.spacing(1, 0),
      marginBottom: 0,
    },

    domainAccountSelector: {
      padding: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    actions: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    libraryContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },

    library: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    libraryEmpty: {
      flex: 1,
      alignItems: 'center',
      backgroundImage: `url(${
        require('../../assets/library-tree-skeleton.svg').default
      })`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'repeat',
      backgroundSize: '100%',
      lineHeight: 1.09,
      letterSpacing: 0.25,
      textAlign: 'center',
      padding: theme.spacing(4, 2),
    },
  });
});

const PlaylistSelector: FC<PlaylistSelectorProps> = ({
  selectedProfileId,
  onSelectProfile,
  onSelectPlaylist,
  onNewPlaylist,
  onNewPresentation,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const libraryFolder = useSelector(selectLibraryFolder);
  const applications = useSelector(selectApplications);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const currentUser = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);
  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);

  // Side-effects

  // Fetch library folder if we have not before.
  useEffect(() => {
    if (libraryFolder) return;
    dispatch(folderActions.fetchLibraryFolder());
  }, [dispatch, libraryFolder]);

  // Fetch applications if we have not before.
  useEffect(() => {
    if (applications.length) return;
    dispatch(applicationActions.fetchApplications());
  }, [dispatch, applications]);

  // Fetch domain if enterprise user and we have not before.
  useEffect(() => {
    if (!isEnterpriseUser || domain) return;
    dispatch(domainActions.fetchDomain());
  }, [dispatch, domain, isEnterpriseUser]);

  // Render

  let selectedProfile: R.ResourceProfile | undefined;

  if (isEnterpriseUser && domain) {
    selectedProfile = domain.r.profiles.find((p) => p.id === selectedProfileId);
  } else if (!isEnterpriseUser && currentUser) {
    // Map full user profile to a resource profile.
    selectedProfile = {
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      thumbnailUrl: currentUser.thumbnailUrl || '',
      domainId: currentUser.domainId,
      domainRole: currentUser.domainRole,
    };
  }

  const renderLibrary = () => {
    if (!libraryFolder) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    const hasFolders = !!libraryFolder.folders.find(
      (f) => !isResourceDeleted(f),
    );
    const hasPlaylists = !!libraryFolder.playlists.find(
      (pl) => !isResourceDeleted(pl),
    );
    if (!hasFolders && !hasPlaylists && !searchQuery) {
      const hasPresentations = !!libraryFolder.presentations.find(
        (p) => !isResourceDeleted(p),
      );

      let emptyStateCta: React.ReactNode;
      if (!hasPresentations) {
        const flyersApp = applications.find(
          (app) => app.id === config.flyersAppId,
        );
        const picturesApp = applications.find(
          (app) => app.id === config.picturesAppId,
        );
        const youtubeApp = applications.find(
          (app) => app.id === config.youtubeAppId,
        );
        const ctaApps = [flyersApp, picturesApp, youtubeApp].filter(
          isNotNullOrUndefined,
        );

        emptyStateCta = (
          <>
            <Heading size={2} weight={500} color="primary">
              Hm, there’s nothing to add-
              <br />
              lets make a presentation, then add it to a playlist
            </Heading>
            {ctaApps.map((app) => (
              <ApplicationCard
                key={app.id}
                application={app}
                onClick={() => onNewPresentation(app.id)}
              />
            ))}
          </>
        );
      } else {
        emptyStateCta = (
          <>
            <Heading size={2} weight={500} color="primary">
              Hm, no playlists-
              <br />
              lets make one and add some of your presentations!
            </Heading>
            <PlaylistCard playlist={defaultPlaylist} onClick={onNewPlaylist} />
          </>
        );
      }

      return (
        <Column doubleMargin className={classes.libraryEmpty}>
          {emptyStateCta}
        </Column>
      );
    }

    if (selectedProfile) {
      return (
        <LibraryTree
          selectMode="playlist"
          searchQuery={searchQuery}
          selectedProfile={selectedProfile}
          onSelectPlaylist={onSelectPlaylist}
          sortOptions={sortOptions}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PaperModal.Body className={classes.headerContainer}>
        <div className={classes.header}>
          {!isEnterpriseUser && <Heading gutterBottom>Your Library</Heading>}

          {isEnterpriseUser && (
            <Paper color="light">
              <DomainAccountSelector
                className={classes.domainAccountSelector}
                selectedProfileId={selectedProfileId}
                onSelect={onSelectProfile}
              />
            </Paper>
          )}

          {selectedProfile && (
            <Paper color="light" className={classes.actions}>
              <PlaylistSelectorActionBar
                selectedProfile={selectedProfile}
                onNewPlaylist={onNewPlaylist}
              />
            </Paper>
          )}
        </div>
      </PaperModal.Body>

      <Scrollable>
        <PaperModal.Body className={classes.libraryContainer}>
          <Paper color="light" className={classes.library}>
            {renderLibrary()}
          </Paper>
        </PaperModal.Body>
      </Scrollable>
    </>
  );
};

export default PlaylistSelector;
