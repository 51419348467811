import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as F from '../../clients/mira/types/Folder';
import * as R from '../../clients/mira/types/Resource';
import { selectUserProfile } from '../../selectors/user';
import { SortFolderOptions, canCreateFolderItem } from '../../utilities';
import { selectSortOptions, selectSearchQuery } from './selectors';
import * as actions from './actions';

interface AddContentActionBarProps {
  folder?: F.Folder | F.VirtualFolder;
  selectedProfile?: R.ResourceProfile;
  sortDisabled?: boolean;
  onCreate: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
      },
    },

    createIcon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
    },

    alert: {
      color: theme.palette.warning.main,
      backgroundColor: '#000',
      borderRadius: 100,
    },
  }),
);

const AddContentActionBar: FC<AddContentActionBarProps> = ({
  selectedProfile,
  sortDisabled,
  onCreate,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const currentUser = useSelector(selectUserProfile);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortFolderOptions['property']) => {
      let direction: SortFolderOptions['direction'] =
        property === 'updatedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (isSearchMode) return;
    dispatch(actions.setSearchQuery(''));
  }, [isSearchMode, dispatch]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'type') {
    sortLabel = `${sortLabel}: Type`;
  } else if (sortOptions.property === 'updatedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByType = sortOptions.property === 'type';
  const isSortByDate = sortOptions.property === 'updatedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={
          isSortByName && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Name"
        selected={isSortByName}
        onClick={() => toggleSort('name')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByType && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Type"
        selected={isSortByType}
        onClick={() => toggleSort('type')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByDate && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Date"
        selected={isSortByDate}
        onClick={() => toggleSort('updatedAt')}
      />
    </>
  );

  const sortAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label={sortLabel}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={sortDisabled}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const createDisabled =
    currentUser &&
    selectedProfile &&
    !canCreateFolderItem(currentUser, selectedProfile);

  const createAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Action
          icon={<AddIcon className={classes.createIcon} />}
          disabled={createDisabled}
          label="Create"
          onClick={onCreate}
        />
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Action
          icon={<AddIcon className={classes.createIcon} />}
          disabled={createDisabled}
          onClick={onCreate}
        />
      </Hidden>
    </>
  );

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      value={searchQuery}
      onChange={(value) => setSearchQuery(value)}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
    />
  );

  return (
    <ActionBar className={classes.root}>
      <Hidden smDown>
        {sortAction}
        {createAction}
        <Spacer />
        {searchAction}
      </Hidden>

      <Hidden mdUp>
        {sortAction}
        {!isSearchMode && createAction}
        {searchAction}
      </Hidden>
    </ActionBar>
  );
};

export default AddContentActionBar;
