import { Device, Profile } from '@raydiant/api-client-js';
import cn from 'classnames';
import Paper from 'raydiant-elements/core/Paper';
import Hidden from 'raydiant-elements/layout/Hidden';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import PlaylistEditIcon from 'raydiant-elements/icons/PlaylistEdit';
import OpenLibraryIcon from 'raydiant-elements/icons/OpenLibrary';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceErrorsById } from '../../selectors/v2/devices';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import {
  canEditResource,
  getDeviceConnectionStatus,
  isResourceDeleted,
} from '../../utilities';
import PublishButton from '../../components/PublishButton';
import DeviceInfo from './DeviceInfo';
import useStyles from './DeviceCard.styles';
import getDeviceContentWarning from './getDeviceContentWarning';
import { selectStatusPopoverDeviceId } from './selectors';
import { clearOpenStatusPopoverOnLoad } from './actions';

interface DeviceCardProps {
  device: Device;
  currentUser: Profile;
  lastLoadedDate: string;
  selected?: boolean;
  selectedPlaylist?: boolean;
  onClick: () => void;
  onSettingsClick: () => void;
  onAiSettingsClick: () => void;
  onEditPlaylistClick: () => void;
  onSelectPlaylistClick: () => void;
  onPublishClick: () => void;
}

const DeviceCard = ({
  device,
  currentUser,
  lastLoadedDate,
  selected,
  selectedPlaylist,
  onAiSettingsClick,
  onSettingsClick,
  onEditPlaylistClick,
  onSelectPlaylistClick,
  onPublishClick,
}: DeviceCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // State

  const [openStatusPopover, setOpenStatusPopover] = useState(false);

  // Selectors

  const playlistsById = useSelector(selectPlaylistsById);
  const contentWarningDeviceId = useSelector(selectStatusPopoverDeviceId);
  const deviceErrorsById = useSelector(selectDeviceErrorsById);

  const devicePlaylist = device.playlistId
    ? playlistsById[device.playlistId]
    : null;

  const deviceErrors = deviceErrorsById[device.id] || [];

  // Unset playlist if playlist was deleted.
  const playlist =
    devicePlaylist && !isResourceDeleted(devicePlaylist)
      ? devicePlaylist
      : null;

  const contentWarning =
    playlist && currentUser
      ? getDeviceContentWarning(device, playlist, currentUser)
      : null;

  const shouldOpenStatusPopoverOnLoad =
    contentWarningDeviceId && contentWarningDeviceId === device.id;

  // Effects

  // Open content warning on page load.
  useEffect(() => {
    if (shouldOpenStatusPopoverOnLoad) {
      // Only open the content warning popover if there is a warning.
      if (contentWarning) {
        setOpenStatusPopover(true);
      }
      // Clear the content warning on load even if there is no warning.
      dispatch(clearOpenStatusPopoverOnLoad());
    }
  }, [shouldOpenStatusPopoverOnLoad, contentWarning, dispatch]);

  // Render

  const { isOnline } = getDeviceConnectionStatus(device, lastLoadedDate);
  const isEditable = canEditResource(currentUser, device.resource);
  const isEditablePlaylist =
    !!device.playlist && canEditResource(currentUser, device.playlist?.resource);

  return (
    <Paper
      className={cn(
        'tour-device',
        classes.root,
        selected && classes.selected,
        isEditable ? classes.editable : classes.notEditable,
        isEditablePlaylist
          ? classes.editablePlaylist
          : classes.notEditablePlaylist,
        isOnline ? classes.online : classes.offline,
      )}
    >
      {/* If we are opening the content warning on load then set focus to an invisible button
      so that the popover and the device are in view. A possible better solution could be to 
      preserve the scroll position while navigating but this needs more thought in order to solve
      for other areas in the Dashboard. */}
      {shouldOpenStatusPopoverOnLoad && (
        <button
          autoFocus
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            opacity: 0,
            bottom: '-200%',
          }}
        />
      )}
      <Paper className={classes.info}>
        <DeviceInfo
          device={device}
          contentWarning={contentWarning}
          deviceErrors={deviceErrors}
          isEditable={isEditable}
          lastLoadedDate={lastLoadedDate}
          onSettingsClick={onSettingsClick}
          onAiSettingsClick={onAiSettingsClick}
          openStatusPopover={openStatusPopover}
          onOpenStatusPopover={setOpenStatusPopover}
        />
      </Paper>

      {!device.isAudioOnly && (
        <Row className={classes.actions}>
          <button
            className={cn(classes.action, classes.editPlaylist)}
            disabled={!isEditablePlaylist}
            onClick={isEditablePlaylist ? onEditPlaylistClick : undefined}
          >
            <div className={classes.iconWithLabel}>
              <PlaylistEditIcon
                fontSize="inherit"
                className={classes.editPlaylistIcon}
              />
              <span>edit</span>
            </div>

            <div className={classes.playlist}>
              {device.playlist ? device.playlist.name : 'Assign a playlist'}
            </div>
          </button>

          <button
            className={cn(
              classes.action,
              'tour-device-playlist-select',
              selectedPlaylist && classes.selectedPlaylist,
            )}
            disabled={!isEditable}
            onClick={onSelectPlaylistClick}
          >
            <div className={classes.iconWithLabel}>
              <OpenLibraryIcon fontSize="inherit" />
              <span>playlists</span>
            </div>
          </button>

          <Hidden xsDown>
            <Spacer />
          </Hidden>

          <PublishButton
            className={classes.publish}
            device={device}
            isOnline={isOnline}
            onClick={onPublishClick}
          />
        </Row>
      )}
    </Paper>
  );
};

export default DeviceCard;
