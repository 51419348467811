import { Grid } from '@material-ui/core';
import useMarketPlaceTiles from '../../hooks/useMarketPlaceTiles';

import MarketplaceTile from '../MarketplaceTile';

const MarketplaceLayout = () => {
  const { data, isLoading } = useMarketPlaceTiles();

  if (!data) return null;

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} md={8}>
        <MarketplaceTile isLoading={isLoading} data={data[0]} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MarketplaceTile isLoading={isLoading} data={data[1]} />
          </Grid>
          <Grid item xs={12}>
            <MarketplaceTile isLoading={isLoading} data={data[2]} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MarketplaceLayout;
