import { Device } from '@raydiant/api-client-js';
import { Theme } from 'raydiant-elements/theme';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
interface DeviceAIStatusProps {
  device: Device;
  onEditSettings: () => void;
}

const DeviceAIStatus = ({ device, onEditSettings }: DeviceAIStatusProps) => {
  const classes = useStyles();

  return (
    <button className={classes.icon} onClick={onEditSettings}>
      <VideocamOutlinedIcon fontSize="large" />
    </button>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      ...buttonReset(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.6,
      padding: theme.spacing(0),
      '&:hover': {
        opacity: 0.8,
      },
    },
  });
});

export default DeviceAIStatus;
