import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Presentation, Theme } from '@raydiant/api-client-js';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Form from 'raydiant-elements/core/Form';
import Column from 'raydiant-elements/layout/Column';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import { canDeleteResource } from '../../utilities';
import { selectUserProfile } from '../../selectors/user';
import { selectThemesById } from '../../selectors/v2/themes';
import useStyles from './PresentationPage.styles';
import ThemeManagerActionBar from './ThemeManagerActionBar';
import ThemeManagerActions from './ThemeManagerActions';
import ThemeManagerItemActions from './ThemeManagerItemActions';
import ThemeManagerItem from './ThemeManagerItem';
import { selectSystemThemes, selectDomainThemesByProfile } from './selectors';
import * as actions from './actions';
import getPageUrl from './getPageUrl';
import getUnsavedThemeId from './getUnsavedThemeId';
import useQueryParams from './useQueryParams';
import useCustomThemes from './useCustomThemes';
import FontUploader from 'raydiant-elements/core/FontUploader';
import useUploadErrorSnackbar from '../../hooks/useUploadErrorSnackbar';
import { Promise } from 'bluebird';
import { uploadFontFile } from '../../utilities/uploadFontFile';
import { useQueryClient } from 'react-query';
import { keys } from '../../queryClient';
import { mapFilesToFileList } from '../../utilities/fileUtils';
import FontUploadDialog from './FontUploadDialog';

export interface ThemeManagerProps {
  presentation?: Presentation;
  onThemeShare: (themeId: string) => void;
}

type FontUploaderState = 'loading' | 'success' | 'idle' | 'error';

let timeout: ReturnType<typeof setTimeout>;

const emptyFileList = new DataTransfer().files;

const ThemeManager = ({ presentation, onThemeShare }: ThemeManagerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const customThemes = useCustomThemes(presentation);

  const { handleUploadError } = useUploadErrorSnackbar();

  // Selectors

  const systemThemes = useSelector(selectSystemThemes);
  const domainThemesByProfile = useSelector(selectDomainThemesByProfile);
  const currentUser = useSelector(selectUserProfile);
  const themesById = useSelector(selectThemesById);
  // State

  const [actionsPopoverEl, setActionsPopoverEl] =
    useState<HTMLButtonElement | null>(null);

  const [selectedActionThemeId, setSelectedActionThemeId] = useState<
    string | null
  >(null);

  const [selectedThemeIds, setSelectedThemeIds] = useState<
    Record<string, boolean>
  >({});

  const selectedActionTheme =
    selectedActionThemeId && themesById[selectedActionThemeId];

  const [isDirty, setIsDirty] = useState(false);

  const [fontFiles, setFontFiles] = useState<FileList>(emptyFileList);
  const [fontUploaderState, setFontUploaderState] =
    useState<FontUploaderState>('idle');

  const [showFontUploadedDialog, setShowFontUploadedDialog] =
    useState<boolean>(false);

  const queryCache = useQueryClient();

  const resetState = (initialState: FontUploaderState) => {
    setFontUploaderState(initialState);

    timeout = setTimeout(() => {
      setFontUploaderState('idle');
      setFontFiles(emptyFileList);
    }, 2500);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  };

  // Callbacks

  const startUpload = async () => {
    setFontUploaderState('loading');

    try {
      await Promise.all([...fontFiles].map(uploadFontFile));

      queryCache.invalidateQueries(keys.fonts());

      resetState('success');
      setIsDirty(true);
      setShowFontUploadedDialog(true);
    } catch (error) {
      resetState('error');
    }
  };

  const toggleTheme = useCallback(
    (themeId: string, selected: boolean) => {
      setSelectedThemeIds({
        ...selectedThemeIds,
        [themeId]: selected,
      });
    },
    [selectedThemeIds],
  );

  const toggleAllThemes = useCallback(
    (selectAll: boolean) => {
      const allSelectedThemeIds: Record<string, boolean> = {};

      // Toggle all user themes
      customThemes.forEach((theme) => {
        if (currentUser && canDeleteResource(currentUser, theme.resource)) {
          allSelectedThemeIds[theme.id] = selectAll;
        }
      });

      // Toggle all domain themes
      domainThemesByProfile.forEach(({ themes }) => {
        themes.forEach((theme) => {
          if (currentUser && canDeleteResource(currentUser, theme.resource)) {
            allSelectedThemeIds[theme.id] = selectAll;
          }
        });
      });

      setSelectedThemeIds(allSelectedThemeIds);
    },
    [customThemes, domainThemesByProfile, currentUser],
  );

  const openThemeActions = useCallback(
    (el: HTMLButtonElement, themeId: string) => {
      setActionsPopoverEl(el);
      setSelectedActionThemeId(themeId);
    },
    [],
  );

  const closeThemeActions = useCallback(() => {
    setActionsPopoverEl(null);
    setSelectedActionThemeId(null);
  }, []);

  const editTheme = useCallback(
    (theme: Theme) => {
      dispatch(actions.clearUnsavedTheme(getUnsavedThemeId(theme)));

      const backTo = getPageUrl({
        presentation,
        themeManager: true,
        queryParams,
      });

      const saveTo = getPageUrl({
        presentation,
        themeManager: true,
        queryParams,
      });

      history.push(
        getPageUrl({
          presentation,
          theme,
          queryParams: {
            ...queryParams,
            backTo,
            saveTo,
          },
        }),
      );
    },
    [queryParams, dispatch, history, presentation],
  );

  // Render

  return (
    <>
      <Form.Section>
        <Text muted>Theme Manager</Text>
        <ThemeManagerActionBar
          selectedThemeIds={selectedThemeIds}
          onSelectAll={toggleAllThemes}
          onShare={onThemeShare}
          onDirty={setIsDirty}
        />
      </Form.Section>
      <Scrollable>
        <Column className={classes.scrollContents}>
          {customThemes.length > 0 && (
            <div>
              <Heading size={5} overline gutterBottom gutterTop>
                Custom Themes
              </Heading>
              {currentUser &&
                customThemes.map((theme) => (
                  <ThemeManagerItem
                    key={theme.id}
                    theme={theme}
                    selectable
                    actionable
                    selected={selectedThemeIds[theme.id]}
                    onSelect={(selected) => toggleTheme(theme.id, selected)}
                    onMore={(el) => openThemeActions(el, theme.id)}
                  />
                ))}
            </div>
          )}
          {systemThemes.length > 0 && (
            <div>
              <Heading size={5} overline gutterBottom gutterTop>
                System Themes
              </Heading>
              {systemThemes.map((theme) => (
                <ThemeManagerItem key={theme.id} theme={theme} />
              ))}
            </div>
          )}


          <div>
            <Heading size={5} overline gutterBottom gutterTop>
              Custom Fonts
            </Heading>
            <FontUploader
              state={fontUploaderState}
              label="Upload Font"
              value={fontFiles}
              onChange={(files) => {
                setFontFiles(files);
              }}
              onUpload={startUpload}
              onError={handleUploadError}
              onDelete={(index) => {
                const updatedList = [...fontFiles].filter(
                  (_file, indexOfArr) => indexOfArr !== index,
                );
                setFontFiles(mapFilesToFileList(updatedList));
                if (updatedList.length === 0) {
                  setFontUploaderState('idle');
                }
              }}
            />
          </div>

          {domainThemesByProfile.map(({ profile, themes }) => {
            if (themes.length === 0) return null;

            return (
              <div key={profile.id}>
                <Heading size={5} overline gutterBottom gutterTop>
                  {profile.name}
                </Heading>
                {currentUser &&
                  themes.map((theme) => (
                    <ThemeManagerItem
                      key={theme.id}
                      theme={theme}
                      selectable
                      actionable
                      selected={selectedThemeIds[theme.id]}
                      onSelect={(selected) => toggleTheme(theme.id, selected)}
                      onMore={(el) => openThemeActions(el, theme.id)}
                    />
                  ))}
              </div>
            );
          })}
        </Column>
      </Scrollable>

      {selectedActionTheme && (
        <ThemeManagerItemActions
          anchorEl={actionsPopoverEl}
          onClose={closeThemeActions}
          theme={selectedActionTheme}
          onEdit={() => editTheme(selectedActionTheme)}
          onShare={() => onThemeShare(selectedActionTheme.id)}
          onDirty={setIsDirty}
        />
      )}

      <ThemeManagerActions isDirty={isDirty} />
      <FontUploadDialog
        open={showFontUploadedDialog}
        onClose={() => setShowFontUploadedDialog(false)}
      />
    </>
  );
};

export default ThemeManager;
