import { FC, ReactNode } from 'react';
import cn from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import { RuleToken, RuleErrorCode, isInitialRuleToken } from './ruleTokens';
import {
  getRuleSourceLabelForId,
  getRuleOperatorSymbolForType,
  getRuleGroupLabelForType,
} from './ruleTokenInputData';
import useStyles from './RuleTokenInput.styles';

export interface RuleTokenButtonProps {
  token: RuleToken;
  selected: boolean;
  error?: RuleErrorCode;
  onClick: () => void;
}

const RuleTokenButton: FC<RuleTokenButtonProps> = ({
  token,
  error,
  selected,
  onClick,
}) => {
  const classes = useStyles();

  let contents: ReactNode[] = [];
  if (token.type === 'open') {
    contents.push(
      <span key="open" className={classes.errorTarget}>
        (
      </span>,
    );
  } else if (token.type === 'close') {
    contents.push(
      <span key="close" className={classes.errorTarget}>
        )
      </span>,
    );
  } else if (token.type === 'rule') {
    if (token.source1) {
      contents.push(
        <span
          key="source1"
          className={cn(error === 'missingNextAndOr' && classes.errorTarget)}
        >
          {getRuleSourceLabelForId(token.source1)}
        </span>,
      );
      if (token.operator) {
        contents.push(
          <span key="symbol" className={classes.symbol}>
            <SvgIcon
              className={cn(
                error === 'missingNextAndOr' && classes.errorTarget,
              )}
            >
              <path
                fill="currentColor"
                d={getRuleOperatorSymbolForType(token.operator)}
              />
            </SvgIcon>
          </span>,
        );
        if (token.source2) {
          contents.push(
            <span
              key="source2"
              className={cn(
                error === 'missingNextAndOr' && classes.errorTarget,
              )}
            >
              {getRuleSourceLabelForId(token.source2)}
            </span>,
          );
        } else {
          contents.push(
            <span key="missing" className={classes.errorTarget}>
              ...
            </span>,
          );
        }
      } else {
        contents.push(
          <span key="missing" className={classes.errorTarget}>
            ...
          </span>,
        );
      }
    } else {
      // Don't show the warning background for the initial rule token.
      contents.push(
        <span
          key="missing"
          className={cn(!isInitialRuleToken(token) && classes.errorTarget)}
        >
          ...
        </span>,
      );
    }
  } else if (token.type === 'and' || token.type === 'or') {
    contents.push(
      <span key="andor" className={classes.errorTarget}>
        {getRuleGroupLabelForType(token.type)}
      </span>,
    );
  }

  return (
    <span
      role="button"
      className={cn(
        classes.token,
        selected && classes.tokenSelected,
        error && classes.tokenError,
      )}
      onClick={onClick}
    >
      {contents}
    </span>
  );
};

export default RuleTokenButton;
