import { useCallback, useState } from 'react';
import cn from 'classnames';
import { Playlist, Presentation } from '@raydiant/api-client-js';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import { PopoverOrigin } from '@material-ui/core/Popover';
import DuplicateIcon from 'raydiant-elements/icons/Duplicate';
import TagIcon from 'raydiant-elements/icons/Tag';
import RuleIcon from 'raydiant-elements/icons/Rule';
import CalendarClockIcon from 'raydiant-elements/icons/CalendarClock';
import PlaylistRemoveIcon from 'raydiant-elements/icons/PlaylistRemove';
import Popover from '../../components/Popover';
import PopoverListItem from '../../components/Popover/PopoverListItem';
import PresentationIcon from '../../components/PresentationIcon';
import DeleteResourcesConfirmation from '../../components/DeleteResourcesConfirmation';
import useCopyPresentation from '../../hooks/useCopyPresentation';
import useCopyPlaylist from '../../hooks/useCopyPlaylist';
import useDeletePresentation from '../../hooks/useDeletePresentation';
import useDeletePlaylist from '../../hooks/useDeletePlaylist';
import useDuplicateSnackbar from '../../hooks/useDuplicateSnackbar';
import useAffectedDevices from '../../hooks/useAffectedDevices';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  canEditResource,
  canDeleteResource,
  getDuplicateResourceName,
} from '../../utilities';
import { createNewId, isNewId } from '../../utilities/identifiers';
import usePlaylistPageContext from './usePlaylistPageContext';

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: -16,
};

const getPlaylistItemResources = (playlist: Playlist) => {
  const resources: Array<Presentation | Playlist> = [];
  for (const item of playlist.items) {
    if (item.playlist) resources.push(item.playlist);
    if (item.presentation) resources.push(item.presentation);
  }
  return resources;
};

const PlaylistItemMoreActions = () => {
  const { enqueueDuplicateSnackbar } = useDuplicateSnackbar();

  const {
    classes,
    state: {
      moreActionsItem: item,
      moreActionsItemPath: path,
      moreActionsAnchorEl: anchorEl,
      moreActionsParentPlaylist: parentPlaylist,
    },
    closeMoreActions,
    removePlaylistItem,
    openModal,
    setEditItemName,
    addPlaylistItems,
    editPresentation,
    removeAllPlaylistItemsForPresentation,
    removeAllPlaylistItemsForPlaylist,
    getPlaylist,
  } = usePlaylistPageContext();

  // We are opening the actions for the top-level playlist if there is no item id.
  const isRootPlaylist = item && item.id === '';

  const { data: currentUser } = useCurrentUser();

  // State

  const [confirmDelete, setConfirmDelete] = useState(false);

  // Queries

  const { data: affectedDevices } = useAffectedDevices(
    {
      playlistIds: item?.playlistId ? [item?.playlistId] : [],
      presentationIds: item?.presentationId ? [item?.presentationId] : [],
      folderIds: [],
    },
    { enabled: confirmDelete },
  );

  // Mutations

  const { mutateAsync: copyPresentation } = useCopyPresentation();
  const { mutateAsync: copyPlaylist } = useCopyPlaylist();
  const { mutateAsync: deletePresentation } = useDeletePresentation();
  const { mutateAsync: deletePlaylist } = useDeletePlaylist();

  // Callbacks

  const closeAndReset = useCallback(() => {
    closeMoreActions();
    setConfirmDelete(false);
  }, [closeMoreActions]);

  const handleEditPlaylistItem = useCallback(() => {
    if (!item?.presentationId) return;
    editPresentation(item.presentationId);
    closeAndReset();
  }, [item, editPresentation, closeAndReset]);

  const handleRemovePlaylistItem = useCallback(() => {
    if (!path) return;
    removePlaylistItem(path);
    closeAndReset();
  }, [closeAndReset, removePlaylistItem, path]);

  const handleEditPlaylistSchedule = useCallback(() => {
    if (!item || !path) return;
    openModal(item, path, 'schedule');
    closeAndReset();
  }, [closeAndReset, openModal, item, path]);

  const handleEditPlaylistRules = useCallback(() => {
    if (!item || !path) return;
    openModal(item, path, 'rules');
    closeAndReset();
  }, [closeAndReset, openModal, item, path]);

  const handleEditPlaylistTags = useCallback(() => {
    if (!item || !path) return;
    openModal(item, path, 'tags');
    closeAndReset();
  }, [closeAndReset, openModal, item, path]);

  const handleEditPresentationTags = useCallback(() => {
    if (!item || !path) return;
    openModal(item, path, 'tags');
    closeAndReset();
  }, [closeAndReset, openModal, item, path]);

  const handleDeletePlaylistItem = useCallback(() => {
    setConfirmDelete(true);
  }, []);

  const handleConfirmDeletePlaylistItem = useCallback(async () => {
    if (!path) return;

    closeAndReset();

    if (item?.presentationId) {
      await deletePresentation({ presentationId: item.presentationId });
      removeAllPlaylistItemsForPresentation(item.presentationId);
    } else if (item?.playlistId) {
      await deletePlaylist({ playlistId: item.playlistId });
      removeAllPlaylistItemsForPlaylist(item.playlistId);
    }
  }, [
    item,
    path,
    closeAndReset,
    deletePresentation,
    deletePlaylist,
    removeAllPlaylistItemsForPresentation,
    removeAllPlaylistItemsForPlaylist,
  ]);

  const handleDuplicatePlaylistItem = useCallback(async () => {
    if (!path) return;

    closeAndReset();

    const parentPlaylistResources = parentPlaylist
      ? getPlaylistItemResources(parentPlaylist)
      : [];

    if (item?.presentation) {
      const newPresentation = await copyPresentation({
        presentationId: item.presentation.id,
        params: {
          name: getDuplicateResourceName(
            item.presentation.name,
            parentPlaylistResources,
          ),
        },
      });

      if (
        currentUser &&
        parentPlaylist &&
        canEditResource(currentUser, parentPlaylist.resource)
      ) {
        addPlaylistItems(path, [
          {
            id: createNewId(),
            presentationId: newPresentation.id,
            presentation: newPresentation,
            playlistId: null,
          },
        ]);
      }

      const isDuplicatingAnotherUsersResource =
        item.presentation.resource.profile.id !== currentUser?.id;
      enqueueDuplicateSnackbar(isDuplicatingAnotherUsersResource);
    } else if (item?.playlist) {
      const newPlaylist = await copyPlaylist({
        playlistId: item.playlist.id,
        params: {
          name: getDuplicateResourceName(
            item.playlist.name,
            parentPlaylistResources,
          ),
        },
      });

      if (!isRootPlaylist) {
        addPlaylistItems(path, [
          {
            id: createNewId(),
            playlistId: newPlaylist.id,
            playlist: newPlaylist,
            presentationId: null,
          },
        ]);
      }

      const isDuplicatingAnotherUsersResource =
        item.playlist.resource.profile.id !== currentUser?.id;
      enqueueDuplicateSnackbar(isDuplicatingAnotherUsersResource);
    }
  }, [
    item,
    path,
    parentPlaylist,
    currentUser,
    addPlaylistItems,
    copyPresentation,
    copyPlaylist,
    closeAndReset,
    enqueueDuplicateSnackbar,
    isRootPlaylist,
  ]);

  const handleEditPlaylistName = useCallback(() => {
    if (!path) return;
    setEditItemName(path);
    closeAndReset();
  }, [path, setEditItemName, closeAndReset]);

  // Render

  let canEdit = false;
  if (currentUser) {
    if (item?.playlist) {
      canEdit = canEditResource(currentUser, item.playlist.resource);
    } else if (item?.presentation) {
      canEdit = canEditResource(currentUser, item.presentation.resource);
    }
  }

  const canRemove =
    currentUser &&
    parentPlaylist &&
    canEditResource(currentUser, parentPlaylist.resource);

  // Show rules and tags if the current user has it enabled and belongs to a domain (enterprise).
  const showRulesAndTags = !!currentUser?.domainId;

  const itemPlaylistOrPresentation = item?.presentation || item?.playlist;
  const itemResource = itemPlaylistOrPresentation?.resource;
  // Current user can if current item is not a new item and they have delete access on the resource.
  const canDelete =
    currentUser &&
    itemPlaylistOrPresentation &&
    !isNewId(itemPlaylistOrPresentation.id) &&
    itemResource &&
    canDeleteResource(currentUser, itemResource);

  const pagePlaylist = item?.playlistId ? getPlaylist(item.playlistId) : null;
  const hasSchedule = !!pagePlaylist?.startDatetime;
  const hasRule = !!pagePlaylist?.rule;
  const hasTags = !!pagePlaylist && pagePlaylist.resource.r.tags.length > 0;

  return (
    <>
      <Popover
        className={classes.playlistItemMoreActions}
        variant="list"
        open={!!anchorEl && !confirmDelete}
        anchorEl={anchorEl}
        onClose={closeAndReset}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className={classes.playlistItemMoreActionsTitle}>
          {item?.playlist && (
            <>
              <PlaylistPlayIcon
                color="inherit"
                className={classes.playlistItemMoreActionsTitleIcon}
              />
              <div className={classes.playlistItemMoreActionsTitleLabel}>
                {item.playlist.name}
              </div>
            </>
          )}
          {item?.presentation && (
            <>
              <div
                className={cn(
                  classes.playlistItemMoreActionsTitleIcon,
                  classes.playlistItemMoreActionsPresentationIcon,
                )}
              >
                <PresentationIcon presentation={item.presentation} />
              </div>
              <div className={classes.playlistItemMoreActionsTitleLabel}>
                {item.presentation.name}
              </div>
            </>
          )}
        </div>

        {!isRootPlaylist && (
          <PopoverListItem
            icon={<PlaylistRemoveIcon fontSize="inherit" />}
            label="Remove"
            onClick={handleRemovePlaylistItem}
            disabled={!canRemove}
          />
        )}

        {item?.presentation && (
          <PopoverListItem
            icon={<EditSharpIcon fontSize="inherit" style={{ fontSize: 18 }} />}
            label="Edit"
            onClick={handleEditPlaylistItem}
            disabled={!canEdit}
          />
        )}

        {item?.presentation && showRulesAndTags && (
          <PopoverListItem
            badge={item?.presentation.resource.r.tags.length > 0}
            icon={<TagIcon fontSize="inherit" />}
            label="Tags"
            onClick={handleEditPresentationTags}
            disabled={!canEdit}
          />
        )}

        {!isRootPlaylist && item?.playlist && (
          <PopoverListItem
            icon={<EditSharpIcon fontSize="inherit" style={{ fontSize: 18 }} />}
            label="Rename"
            onClick={handleEditPlaylistName}
            disabled={!canEdit}
          />
        )}

        {item?.playlist && (
          <PopoverListItem
            badge={hasSchedule}
            icon={<CalendarClockIcon fontSize="inherit" />}
            label="Schedule"
            onClick={handleEditPlaylistSchedule}
            disabled={!canEdit}
          />
        )}

        {item?.playlist && showRulesAndTags && (
          <PopoverListItem
            badge={hasRule}
            icon={<RuleIcon fontSize="inherit" />}
            label="Rules"
            onClick={handleEditPlaylistRules}
            disabled={!canEdit}
          />
        )}

        {item?.playlist && showRulesAndTags && (
          <PopoverListItem
            badge={hasTags}
            icon={<TagIcon fontSize="inherit" />}
            label="Tags"
            onClick={handleEditPlaylistTags}
            disabled={!canEdit}
          />
        )}

        <PopoverListItem
          icon={<DuplicateIcon fontSize="inherit" style={{ fontSize: 18 }} />}
          label="Duplicate"
          onClick={handleDuplicatePlaylistItem}
        />

        {!isRootPlaylist && (
          <PopoverListItem
            icon={<DeleteForeverIcon fontSize="inherit" color="error" />}
            label="Delete"
            onClick={handleDeletePlaylistItem}
            disabled={!canDelete}
          />
        )}
      </Popover>

      {anchorEl && affectedDevices && (
        <DeleteResourcesConfirmation
          anchorEl={anchorEl}
          open={confirmDelete}
          resourceName={itemPlaylistOrPresentation?.name}
          affectedDevices={affectedDevices}
          onClose={closeAndReset}
          onDelete={handleConfirmDeletePlaylistItem}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        />
      )}
    </>
  );
};

export default PlaylistItemMoreActions;
