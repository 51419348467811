import { FC, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import useStyles from './TagInput.styles';
import Row from 'raydiant-elements/layout/Row';
import TagIcon from 'raydiant-elements/icons/Tag';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Popover from '@material-ui/core/Popover';
import Spacer from 'raydiant-elements/layout/Spacer';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import { Day } from '@raydiant/api-client-js';

export interface TagInputDaysTypeProps {
  label: string;
  value: Day[];
  onChange: (value: Day[]) => void;
}

const daysOfTheWeek = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

const TagInputDaysType: FC<TagInputDaysTypeProps> = ({
  label,
  value: initialValue,
  onChange,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(initialValue);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClose = useCallback(() => {
    onChange(selectedDays);
    setOpen(false);
  }, [onChange, selectedDays]);

  const handleDone = useCallback(() => {
    onChange(selectedDays);
    setOpen(false);
  }, [onChange, selectedDays]);

  const isDirty = useMemo(() => {
    return initialValue !== selectedDays;
  }, [initialValue, selectedDays]);

  useEffect(() => {
    setSelectedDays(initialValue);
  }, [initialValue]);

  return (
    <div className={classes.root}>
      <Row halfMargin center>
        <TagIcon className={classes.icon} />
        <div className={classes.tag}>
          <span>{label}:</span>
          &nbsp;
          <input
            ref={inputRef}
            className={classes.tagTimeInput}
            onClick={() => setOpen(true)}
            readOnly
            value={selectedDays.length > 0 ? selectedDays.join(', ') : selectedDays}
          />
          <Popover
            open={open}
            anchorEl={inputRef.current}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.tagPopOverContainer}>
              <InputLabel>Days of the week</InputLabel>
              <ToggleButtonGroup
                value={selectedDays}
                size="large"
                onChange={(_e, value) => setSelectedDays(value)}
              >
                {daysOfTheWeek.map(day => (
                  <ToggleButton value={day} key={day}>
                    {day}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <InputHelperText indent>Select one or more days</InputHelperText>
            </div>
            <div className={classes.tagInputActionsContainer}>
              <Row className={classes.tagInputActions}>
                <ActionBar.Action
                  icon={<CancelIcon />}
                  color="error"
                  label="Cancel"
                  onClick={handleClose}
                />
                <Spacer />
                <ActionBar.Action
                  icon={<CheckCircleIcon />}
                  color="success"
                  label="Done"
                  disabled={!isDirty}
                  onClick={handleDone}
                />
              </Row>
            </div>
          </Popover>
        </div>
      </Row>
    </div>
  );
};

export default TagInputDaysType;
