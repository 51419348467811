import { useCallback, useRef, useEffect, useState } from 'react';
import * as React from 'react';
import { Theme, Presentation } from '@raydiant/api-client-js';
import ColorField from 'raydiant-elements/core/ColorField';
import TextField from 'raydiant-elements/core/TextField';
import FileField from 'raydiant-elements/core/FileField';
import Text from 'raydiant-elements/core/Text';
import Form from 'raydiant-elements/core/Form';
import Hidden from 'raydiant-elements/layout/Hidden';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import TextStyleField from '../../components/TextStyleField';
import useStyles from './PresentationPage.styles';
import ThemeFormActions from './ThemeFormActions';
import { FontFamily } from '../../components/TextStyleField/fontFamilies';
import {
  mapFilesToFileList,
  mapFileToFileUpload,
  parseUrlToFileList,
} from '../../utilities/fileUtils';
import ThemeBackgroundScale from './ThemeBackgroundScale';
import { ThemeFile } from '../../actions/themes';

interface ThemeFormProps {
  fonts: FontFamily[];
  onChange: (theme: Theme) => void;
  presentation: Presentation | undefined;
  preview: React.ReactNode;
  theme?: Theme;
}

const ThemeForm = ({
  fonts,
  onChange,
  presentation,
  preview,
  theme,
}: ThemeFormProps) => {
  const classes = useStyles();

  const defaultBackGroundImage: FileList = theme?.backgroundImageFileUpload?.url
    ? parseUrlToFileList(
        theme?.backgroundImageFileUpload?.url,
        theme.backgroundImageFileUpload.filename,
        {
          type: theme.backgroundImageFileUpload.contentType,
        },
      )
    : mapFilesToFileList([new File([''], '')]);

  // State

  const [themeName, setThemeName] = useState<string | null>(null);
  const [pendingBackgroundImageUpload, setPendingBackgroundImageUpload] = useState<ThemeFile | null>(null);

  const [themeBackgroundImage, setThemeBackgroundImage] = useState<FileList | undefined>(
    defaultBackGroundImage,
  );
  // Refs

  const throttledRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Callbacks

  const throttledOnChange = useCallback(
    (updatedTheme: Theme) => {
      if (throttledRef.current) {
        clearTimeout(throttledRef.current);
      }

      throttledRef.current = setTimeout(() => onChange(updatedTheme), 150);
    },
    [onChange],
  );

  // Effects

  // Update themeName state when theme is loaded
  useEffect(() => {
    if (!theme) return;
    setThemeName(theme.name);
  }, [theme]);

  // Clear throttle timeout on unmount.
  useEffect(() => {
    return () => {
      if (throttledRef.current) {
        clearTimeout(throttledRef.current);
      }
    };
  }, []);

  if (!theme) return null;

  return (
    <>
      <Scrollable>
        <Hidden smUp>{preview}</Hidden>

        <Form.Section>
          <Text muted>Theme Builder</Text>
        </Form.Section>

        <Column className={classes.scrollContents} doubleMargin>
          <TextField
            label="name"
            value={themeName ?? theme.name}
            onChange={setThemeName}
            onBlur={() => {
              if (themeName) {
                onChange({ ...theme, name: themeName });
              }
            }}
          />

          <TextStyleField
            label="Heading 1 Text"
            fonts={fonts}
            fontUrl={theme.headingFontNew?.fileUpload.url || theme.headingFont}
            color={theme.headingTextColor}
            onFontChange={(headingFont) => {
              onChange({ ...theme, headingFont });
            }}
            onColorChange={(headingTextColor) => {
              throttledOnChange({ ...theme, headingTextColor });
            }}
          />

          <TextStyleField
            label="Heading 2 Text"
            fonts={fonts}
            fontUrl={
              theme.heading2FontNew?.fileUpload.url || theme.heading2Font
            }
            color={theme.heading2TextColor}
            onFontChange={(heading2Font) => {
              onChange({ ...theme, heading2Font });
            }}
            onColorChange={(heading2TextColor) => {
              throttledOnChange({ ...theme, heading2TextColor });
            }}
          />

          <TextStyleField
            label="Body Text"
            fonts={fonts}
            fontUrl={theme.bodyFontNew?.fileUpload.url || theme.bodyFont}
            color={theme.bodyTextColor}
            onFontChange={(bodyFont) => {
              onChange({ ...theme, bodyFont });
            }}
            onColorChange={(bodyTextColor) => {
              throttledOnChange({ ...theme, bodyTextColor });
            }}
          />

          <>
            <FileField
              accept={['image/jpg', 'image/png']}
              helperText=""
              label="Background Image"
              value={themeBackgroundImage}
              onChange={(fileList) => {
                setThemeBackgroundImage(fileList);
                const [file] = fileList;
                const fileUpload = mapFileToFileUpload(file);

                setPendingBackgroundImageUpload({
                  file,
                  localUrl: fileUpload.url,
                });
                onChange({
                  ...theme,
                  backgroundImageFileUpload: fileUpload,
                });
              }}
              onClear={() => {
                setThemeBackgroundImage(undefined);
                onChange({
                  ...theme,
                  backgroundImageFileUpload: null,
                });
              }}
            />
            <ThemeBackgroundScale
              value={theme.backgroundScale}
              onChange={(value) => {
                onChange({
                  ...theme,
                  backgroundScale: value,
                });
              }}
            />
          </>

          <ColorField
            label="Background Color"
            value={theme.backgroundColor}
            onChange={(backgroundColor) =>
              throttledOnChange({ ...theme, backgroundColor })
            }
          />

          <ColorField
            label="Border Color"
            value={theme.borderColor}
            onChange={(borderColor) =>
              throttledOnChange({ ...theme, borderColor })
            }
          />
        </Column>
      </Scrollable>
      <ThemeFormActions
        presentation={presentation}
        theme={theme}
        pendingBackgroundImageUpload={pendingBackgroundImageUpload ?? undefined}
        onChange={onChange}
      />
    </>
  );
};

export default ThemeForm;
