import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';
import { FC } from 'react';

type UpdatePaymentMethodConfirmDialogProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
};
const UpdatePaymentMethodConfirmDialog: FC<
  UpdatePaymentMethodConfirmDialogProps
> = ({ handleCancel, handleConfirm }) => {
  return (
    <>
      <DialogTitle>Update Payment Method</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm that you'd like to update your payment method. We'll
          send over an email detailing how to proceed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="default">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default UpdatePaymentMethodConfirmDialog;
